import axios from "axios";
import { showNotification } from "@astrocentro-webapp/sellers/modules/commons/utils/showNotification";

const defaultOptions = {
  headers: {
    Authorization: `Bearer ${import.meta.env.VITE_ASTROCENTRO_API_TOKEN}`,
    Accept: "application/json",
  },
};

export class HttpClient {
  static create(
    baseURL = import.meta.env.VITE_ASTROCENTRO_API_URL,
    options = defaultOptions
  ) {
    const api = axios.create({
      baseURL,
    });

    api.interceptors.request.use((config) => {
      localStorage.accessToken &&
        (options.headers.Authorization = `Bearer ${localStorage.accessToken}`);

      return {
        ...config,
        ...options,
      };
    });

    api.interceptors.response.use(
      ({ data, status }) => {
        return {
          data,
          status,
        };
      },
      (error) => {
        const errorMessage = error.response?.data?.message
          ? error.response.data.message
          : error.message;

        showNotification("error", errorMessage);
        throw new Error(errorMessage);
      }
    );

    return api;
  }
}
