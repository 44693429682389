import { ChatEvents as BaseChatEvents } from "@astrocentro-webapp/commons/modules/chat/events/ChatEvents";
import { storeMessageInLocalDatabaseListener } from "@astrocentro-webapp/sellers/modules/chat/listeners/storeMessageInLocalDatabaseListener";
import { emitMessageSentEventToSocketListener } from "@astrocentro-webapp/sellers/modules/chat/listeners/emitMessageSentEventToSocketListener";
import { updateMessageHasErrorListener } from "@astrocentro-webapp/sellers/modules/chat/listeners/updateMessageHasErrorListener";

export class ChatEvents extends BaseChatEvents {
  /**
   * @type {Map}
   * @public
   */
  events;

  constructor() {
    super();

    if (ChatEvents._instance) {
      return ChatEvents._instance;
    }

    this.events = new Map();

    ChatEvents._instance = this;
  }

  /**
   * Subscribe to the events
   */
  subscribe() {
    if (!this._subscribed) {
      this.events.set(
        "message-sent",
        new Set([
          async (message) => {
            await storeMessageInLocalDatabaseListener(message);
            emitMessageSentEventToSocketListener(message);
            updateMessageHasErrorListener(message);
          },
        ])
      );

      this._subscribed = true;
    }
  }
}
