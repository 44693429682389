import { computedEager } from "@vueuse/core";

import { useBreakpoints } from "@astrocentro-webapp/ui-components/modules/commons/composables/browser/useBreakpoints";

export const useBreakpointSmallerThanMd = () => {
  const breakpoints = useBreakpoints();
  const isSmallerThanMd = computedEager(() => breakpoints.smaller("md").value);

  return { isSmallerThanMd };
};
