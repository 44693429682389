<script setup>
import { ref, computed, onMounted, watch } from "vue";

import { DateHelper } from "@astrocentro-webapp/commons/modules/commons/helpers/DateHelper";

const props = defineProps({
  consultationId: {
    type: Number,
    default: 0,
  },
  startDate: {
    type: String,
    default: "",
  },
  endDate: {
    type: String,
    default: "",
  },
  shouldStop: {
    type: Boolean,
  },
});
const timer = ref("");
const intervalId = ref(0);
const dateHelper = new DateHelper();
const formattedTimer = computed(() => (timer.value ? timer.value : "00:00"));
const handleNumberFormat = (number) => (number < 10 ? `0${number}` : number);
const handleTimerFormat = (startDate = null, endDate = null) => {
  if (!startDate) {
    return `00:00`;
  }

  const { minutes, seconds } = dateHelper.intervalToDuration({
    start: dateHelper.get(startDate),
    end: endDate ? dateHelper.get(endDate) : dateHelper.get(),
  });

  return `${handleNumberFormat(minutes)}:${handleNumberFormat(seconds)}`;
};
const handleTimer = () => {
  if (props.shouldStop) {
    clearInterval(intervalId.value);
    return (timer.value = handleTimerFormat(props.startDate, props.endDate));
  }

  if (props.startDate) {
    clearInterval(intervalId.value);
    return (intervalId.value = setInterval(
      () => (timer.value = handleTimerFormat(props.startDate)),
      1000
    ));
  }
};

onMounted(() => handleTimer());
watch([() => props.startDate, () => props.shouldStop], () => handleTimer());
</script>

<template>
  <span>{{ formattedTimer }}</span>
</template>
