import { useConsultationsStore } from "@astrocentro-webapp/sellers/stores/consultations";
import { PlayStopNewConsultationAudio } from "@astrocentro-webapp/sellers/modules/commons/audios/PlayStopNewConsultationAudio.js";

export const inactiveConsultationListener = async (event) => {
  const consultationsStore = useConsultationsStore();
  const newConsultationAudio = new PlayStopNewConsultationAudio();

  consultationsStore.$patch(async (state) => {
    let activeCalls = await state.consultations["Chamadas Ativas"];
    let inactiveCalls = await state.consultations["Chamadas Inativas"];
    newConsultationAudio.stop();

    activeCalls = activeCalls.filter((item) => {
      if (item.id == event.consultation.id) {
        inactiveCalls.push({ ...item, ...event.consultation });
      }
      return item.id != event.consultation.id;
    });

    state.consultations = {
      "Chamadas Ativas": activeCalls,
      "Chamadas Inativas": inactiveCalls,
    };
  });
};
