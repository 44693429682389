<script setup>
import { ref, onMounted, computed, watch, inject } from "vue";
import { useOnline } from "@vueuse/core";

import { ChatHeader } from "@astrocentro-webapp/ui-components/modules/chat/components/ChatHeader";
import { ChatMessages } from "@astrocentro-webapp/ui-components/modules/chat/components/ChatMessages";
import { ChatInput } from "@astrocentro-webapp/ui-components/modules/chat/components/ChatInput";
import { ChatConversationPlaceholder } from "@astrocentro-webapp/ui-components/modules/chat/components/ChatConversationPlaceholder";
import { ChatFeedback } from "@astrocentro-webapp/ui-components/modules/chat/components/ChatFeedback";
import { SetChatChannelStatusService } from "@astrocentro-webapp/commons/modules/chat/services/SetChatChannelStatusService";
import { ChatConnectionStatus } from "@astrocentro-webapp/ui-components/modules/chat/components/ChatConnectionStatus";
import { useBreakpointSmallerThanMd } from "@astrocentro-webapp/ui-components/modules/commons/composables/browser/useBreakpointSmallerThanMd";

const props = defineProps({
  user: {
    type: Object,
    default() {
      return { id: null, username: null };
    },
  },
  isLoading: {
    type: Boolean,
    default: true,
  },
  consultation: {
    type: Object,
    default() {
      return {
        id: 0,
        chat_room_id: 0,
        start_date: "",
        last_status: "",
      };
    },
  },
  conversations: {
    type: Object,
    default() {
      return [];
    },
  },
});

const isLoading = ref(props.isLoading);
const isOnline = useOnline();
const { isSmallerThanMd } = useBreakpointSmallerThanMd();
const chatConsultationChannelEvents = inject(
  "chat-consultation-channel-events"
);
const setChatChannelStatusService = new SetChatChannelStatusService(
  chatConsultationChannelEvents?.channelName
);
const recipientUser = computed(() => {
  const recipientUserType = props.user.type == "seller" ? "customer" : "seller";

  return props.consultation[recipientUserType];
});

const setData = async (consultationId) => {
  setChatChannelStatusService.perform();

  if (consultationId) {
    isLoading.value = true;
    setTimeout(() => (isLoading.value = false), 2000);
  }
};

onMounted(() => {
  if (props.consultation?.id) setData(props.consultation?.id);
});

watch(
  () => props.consultation?.id,
  (consultationId) => {
    setData(consultationId);
  }
);

watch(isOnline, () => {
  setChatChannelStatusService.perform();
});
</script>

<template>
  <div class="h-100">
    <ChatConversationPlaceholder v-if="isLoading" />
    <div class="d-flex flex-column h-100" v-else>
      <ChatHeader
        :recipientUser="recipientUser"
        :consultation="props.consultation"
        @open-chat-note="$emit('open-chat-note')"
        @intends-finish-consultation="$emit('intends-finish-consultation')"
      />
      <ChatConnectionStatus
        v-if="
          !isSmallerThanMd && props.consultation?.last_status !== 'finished'
        "
        :channelName="chatConsultationChannelEvents?.channelName"
      />
      <ChatMessages
        :user="props.user"
        :recipientUser="recipientUser"
        :conversations="props.conversations"
        :consultation="props.consultation"
      />
      <ChatInput
        :consultation="props.consultation"
        :user="props.user"
        :consultationId="props.consultation.id"
        v-if="props.consultation.last_status === 'in-progress'"
      />
      <ChatFeedback
        :userType="props.user.type"
        :consultation="props.consultation"
      />
    </div>
  </div>
</template>
