<script setup>
import { useRouter } from "vue-router";
import { reactive } from "vue";

import { VModal } from "@astrocentro-webapp/ui-components/modules/commons/components/VModal";
import { VButton } from "@astrocentro-webapp/ui/modules/commons/components/VButton";
import { FinishChatConsultationService } from "@astrocentro-webapp/sellers/modules/chat/services/FinishChatConsultationService.js";

const finishChatConsultationService = new FinishChatConsultationService();
const emit = defineEmits(["modal-closed"]);
const route = useRouter();

let state = reactive({
  submitting: false,
});

const props = defineProps({
  open: {
    type: Boolean,
    default: false,
  },
  consultation: {
    type: Object,
    default() {
      return {
        id: null,
        customer: {
          name: null,
        },
      };
    },
  },
});

const finishConsultation = async (consultationId) => {
  state.submitting = true;
  await finishChatConsultationService.perform(consultationId);
  state.submitting = false;
  route.push(
    `/?displayConsultationSummary=true&consultationId=${consultationId}`
  );
};
</script>

<template>
  <VModal
    :open="props.open"
    id="finishing-modal"
    @modal-closed="$emit('modal-closed')"
    class="text-center"
    :logo="true"
  >
    <div class="p-md-5 pt-md-0 mx-md-5">
      <h1 class="mb-3">Você tem certeza que deseja encerrar essa consulta?</h1>
      <p class="text-gray-500 mb-5">
        Você encerra a consulta com o seu cliente
        <strong class="text-dark">{{
          props.consultation.customer?.name
        }}</strong
        >, e não poderá mais continuar o atendimento.
      </p>
      <div class="d-grid mt-5">
        <VButton
          :disabled="state.submitting"
          class="btn-lg btn-primary mb-3 fw-bold"
          @click="finishConsultation(consultation.id)"
          >Sim, quero encerrar...</VButton
        >
        <VButton
          class="btn-lg btn-outline-gray-300 text-gray-700 fw-bold"
          @click="$emit('modal-closed')"
          >Voltar para o atendimento</VButton
        >
      </div>
    </div>
  </VModal>
</template>
