<template>
  <div class="h-100 d-flex flex-column position-relative">
    <div
      class="d-flex align-items-center justify-content-between px-3 py-2 border-bottom border-gray-100"
    >
      <span class="placeholder text-gray-300 col-3 my-2"></span>
      <span class="placeholder text-gray-300 col-5 my-2"></span>
    </div>
    <div class="w-100 h-100 p-3">
      <div class="d-flex flex-column" v-for="index in 2" :key="index">
        <span class="placeholder text-gray-300 col-5 my-2"></span>
        <span class="placeholder text-gray-300 col-4 my-2"></span>
        <span class="placeholder text-gray-300 col-4 my-2 mb-3"></span>
      </div>
      <div class="d-flex flex-column" v-for="index in 2" :key="index">
        <span
          class="placeholder text-gray-300 col-5 my-2 align-self-end"
        ></span>
        <span
          class="placeholder text-gray-300 col-4 my-2 align-self-end"
        ></span>
        <span
          class="placeholder text-gray-300 col-4 my-2 mb-3 align-self-end"
        ></span>
      </div>
      <div class="d-flex flex-column" v-for="index in 2" :key="index">
        <span class="placeholder text-gray-300 col-5 my-2"></span>
        <span class="placeholder text-gray-300 col-4 my-2"></span>
        <span class="placeholder text-gray-300 col-4 my-2 mb-3"></span>
      </div>
    </div>
  </div>
</template>
