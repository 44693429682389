import { useServicesStore } from "@astrocentro-webapp/sellers/stores/services";

export const getConsultationType = async (consultation) => {
  const servicesStore = useServicesStore();
  const sellerServices = await servicesStore.services;

  if (!sellerServices) {
    return null;
  }

  if (!consultation || !("seller_service_id" in consultation)) {
    return null;
  }

  if (
    consultation.seller_service_id === sellerServices["phone-consultation"].id
  ) {
    return "phone-consultation";
  }

  return "chat-consultation";
};
