import { HttpClient } from "@astrocentro-webapp/sellers/modules/commons/clients/HttpClient";
import { UserRepository } from "@astrocentro-webapp/sellers/modules/commons/repositories/UserRepository"

export class LoginService {
  #httpClient;
  #userRepository;

  constructor() {
    this.#httpClient = HttpClient.create();
    this.#userRepository = new UserRepository();
  }

  /**
   * Handle user authentication
   *
   * @param {string} username
   * @param {string} password
   *
   * @returns {string|null}
   */
  async perform(username, password) {
    const response = await this.#httpClient.post(
      `${import.meta.env.VITE_ASTROCENTRO_API_URL}/v1/auth/login`,
      {
        username: username,
        password: password,
      }
    );

    const accessToken = response?.data?.access_token;

    accessToken && (await this.#storeLoggedInUserData(accessToken));

    return accessToken;
  }

  /**
   * Stores the logged in user data and access token
   * 
   * @param {String} accessToken 
   */
  async #storeLoggedInUserData(accessToken)
  {
    localStorage.accessToken = accessToken
    const user = await this.#userRepository.getLoggedInUser();

    localStorage.user = JSON.stringify(user);
  }

  /**
   * Gets the logged in user data
   * 
   * @returns {JSON}
   */
  async getLoggedInUser()
  {
    if (!localStorage.user) {
      return null;
    }
    return JSON.parse(localStorage.user);
  }
}
