import { BaseRepository } from "@astrocentro-webapp/commons/modules/commons/repositories/BaseRepository";

export class UserRepository extends BaseRepository {
  constructor() {
    super();

    this.resource = "users";
  }

  /**
   * Get the logged in user
   *
   * @returns {JSON}
   */
  async getLoggedInUser() {
    const { data } = await this.httpClient.get("v1/current-user");

    return data;
  }
}
