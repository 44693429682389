<script setup>
import { onMounted, reactive } from "vue";
import { Toast } from "bootstrap";
import { data } from "./data";

const state = reactive({
  type: "error",
  message: "Ooops, aconteceu um erro",
});

onMounted(() => {
  var notificationToastElement = document.getElementById("notification-toast");
  var notificationToast = new Toast(notificationToastElement);

  document.addEventListener("show-notification", (event) => {
    state.type = event.detail.type;
    state.message = event.detail.message;
    notificationToast.show();
  });
});
</script>

<template>
  <div class="toast-container position-absolute p-3 top-0 end-0">
    <div
      :class="`toast rounded-2 bg-${
        data[state.type].color
      } text-white border-0 shadow`"
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
      id="notification-toast"
    >
      <div class="d-flex">
        <div class="toast-body">
          {{ state.message }}
        </div>
        <button
          type="button"
          class="btn-close btn-close-white me-2 m-auto"
          data-bs-dismiss="toast"
          aria-label="Close"
        ></button>
      </div>
    </div>
  </div>
</template>
