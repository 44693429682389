export class FormatSellerServicesHelper {
  /**
   * @typedef {Object} Service
   * @property {number} id
   * @property {number} seller_id
   * @property {string} service
   * @property {number} price
   * @property {string} status
   * @property {Date} created_at
   * @property {number} updated_at
   */

  /**
   * Format seller services as object.
   *
   * @param {Service[]} services
   *
   * @returns {Object}
   */
  static async perform(services) {
    const formattedServices = {};
    services.forEach(({ service, ...props }) => {
      formattedServices[service] = {
        ...props,
        service,
      };
    });

    return formattedServices;
  }
}
