import { ConversationRepository } from "@astrocentro-webapp/sellers/modules/commons/repositories/ConversationRepository";
import { ChatRoomConversationRepository } from "@astrocentro-webapp/sellers/modules/commons/repositories/ChatRoomConversationRepository";
import { DateHelper } from "@astrocentro-webapp/sellers/modules/commons/helpers/DateHelper";
import { FormatConversationMessagesHelper } from "@astrocentro-webapp/commons/modules/chat/helpers/FormatConversationMessagesHelper";

export class CreateConversationService {
  dateHelper;
  conversationRepository;

  constructor() {
    this.dateHelper = new DateHelper();
    this.conversationRepository = new ConversationRepository();
  }

  /**
   * Create a conversation in local database.
   *
   * @param {Object} consultation
   */
  async perform(consultation) {
    const chatRoomConversationRepository = new ChatRoomConversationRepository(
      consultation.chat_room_id
    );
    const currentDate = this.dateHelper.format(null, "yyyy-MM-dd");
    const conversation = await chatRoomConversationRepository.get(
      `?filters[]=chat_room_id,=,${consultation.chat_room_id}&filters[]=date,=,${currentDate}`
    );

    if (!conversation) {
      return this.conversationRepository.create({
        date: currentDate,
        messages: [],
        chatRoomId: consultation.chat_room_id,
      });
    }

    const messages = FormatConversationMessagesHelper.perform(
      conversation,
      consultation
    );

    const localConversation = await this.conversationRepository.getFirst({
      date: currentDate,
      chatRoomId: consultation.chat_room_id,
    });

    if (!localConversation) {
      return this.conversationRepository.create({
        date: currentDate,
        messages,
        chatRoomId: consultation.chat_room_id,
      });
    }

    return this.conversationRepository.update(localConversation.id, {
      messages,
    });
  }
}
