import { useBreakpoints as baseUseBreakpoints } from "@vueuse/core";

export const useBreakpoints = () =>
  baseUseBreakpoints({
    xs: 0,
    sm: 540,
    md: 720,
    lg: 960,
    xl: 1140,
    xxl: 1141,
  });
