import {
  setDefaultOptions,
  format as dateFnsFormat,
  isToday as dateFnsIsToday,
  parseISO as dateFnsParseISO,
  intlFormat as dateFnsIntlFormat,
  intervalToDuration as dateFnsIntervalToDuration,
} from "date-fns";
import { ptBR as ptBRLocale } from "date-fns/locale";

export class DateHelper {
  constructor(options) {
    setDefaultOptions({ locale: options?.locale || ptBRLocale });
  }

  /**
   * Return a date object.
   *
   * @param {(string | Number | null)} date
   * @returns {Date}
   */
  get(date = null) {
    if (date) {
      if (typeof date === "string") {
        return new Date(dateFnsParseISO(date));
      }

      return date;
    }

    return new Date();
  }

  /**
   * Return a formatted date string in the given format. The result may vary by locale.
   *
   * @param {(string | Number | null)} date
   * @param {string} format
   * @param {Object} options
   * @returns {string}
   */
  format(date, format, options = null) {
    const newDate = this.get(date);

    return dateFnsFormat(newDate, format, options);
  }

  /**
   * Return a formatted date as intl string in the given format.
   *
   * @param {(string | Number | null)} date
   * @param {Object} options
   * @returns {string}
   */
  intlFormat(date, options = null) {
    const newDate = this.get(date);

    return dateFnsIntlFormat(newDate, options);
  }

  /**
   * Return if the given date is today.
   *
   * @param {(string | Number | null)} date
   * @returns {boolean}
   */
  isToday(date) {
    const newDate = this.get(date);

    return dateFnsIsToday(newDate);
  }

  /**
   * Return the given interval between two dates as an object containg years, months, days, hours, minutes and seconds. See more at {@link https://date-fns.org/v2.29.3/docs/intervalToDuration}
   *
   * @param {Object} interval
   * @returns {Object}
   */
  intervalToDuration(interval) {
    return dateFnsIntervalToDuration(interval);
  }
}
