import { BaseRepository } from "@astrocentro-webapp/sellers/modules/commons/repositories/BaseRepository";

export class ChatRoomConversationRepository extends BaseRepository {
  constructor(chatRoomId) {
    super();

    this.resource = `chat-rooms/${chatRoomId}/conversations`;
  }

  /**
   * Get the related resources
   *
   * @param {string} params
   *
   * @returns {JSON}
   */
  async get(params = null) {
    const {
      data: [conversation],
    } = await this.httpClient.get(`v1/${this.resource}${params}`);

    return conversation;
  }
}
