export class ChannelEvents {
  channelName;
  channelStatusStore;

  constructor(channelName) {
    this.channelName = channelName;
    this.channelStatusStore = null;
  }

  /**
   * Get channel instance by channel's name
   *
   */
  get channel() {
    return window.webSocketAdapter.channel(this.channelName);
  }

  /**
   * Subscribe to the channel events and register its listeners
   *
   */
  subscribe() {
    if (this.channel) {
      if (!this.channel.subscribed) {
        this.bindListeners();
      }

      return;
    }

    this.bindListeners();
  }

  /**
   * Unsubscribe from the channel
   *
   */
  unsubscribe() {
    window.webSocketAdapter.unsubscribe(this.channelName);
  }

  /**
   * Send event to the channel
   *
   */
  trigger(event, data) {
    try {
      return window.webSocketAdapter
        .channel(this.channelName)
        .trigger(event, data);
    } catch (error) {
      // TODO: Error occurs when client can't trigger event because it's currently not subscribed to channel
      console.log("[ trigger - commons ]", error);
    }
  }

  /**
   * Trigger an event after subscribed to the channel (wait for a maximum of 30 seconds)
   *
   * @param {string} event
   * @param {JSON} data
   */
  triggerAfterSubscribed(event, data) {
    let loop = 1;

    const interval = setInterval(() => {
      if ((this.channel && this.channel.subscribed) || loop > 29) {
        clearInterval(interval);
      }

      if (this.channel && this.channel.subscribed) {
        return window.webSocketAdapter
          .channel(this.channelName)
          .trigger(event, data);
      }

      loop++;
    }, 1000);
  }

  bindListeners() {
    return null;
  }
}
