import { ConversationRepository } from "@astrocentro-webapp/sellers/modules/commons/repositories/ConversationRepository";
import { DateHelper } from "@astrocentro-webapp/sellers/modules/commons/helpers/DateHelper";

export const updateMessageHasErrorListener = async ({ chatRoomId, id }) => {
  const conversationRepository = new ConversationRepository();
  const dateHelper = new DateHelper();
  const currentDate = dateHelper.format(null, "yyyy-MM-dd");
  const fiveSecondsTimeout = 1 * 1000 * 5;

  // TODO: use getFirst and update methods from ConversationMessageRepository instead
  try {
    setTimeout(async () => {
      const conversation = await conversationRepository.getFirst({
        date: currentDate,
        chatRoomId,
      });
      let storedData = { message: null, index: -1 };

      conversation?.messages.forEach((message, index) => {
        if (message.id === id) {
          storedData = { ...storedData, message, index };
        }
      });

      if (storedData.message !== null && !storedData.message?.isRead) {
        storedData.message.hasError = true;

        return conversationRepository.update(conversation.id, {
          [`messages.${storedData.index}`]: storedData.message,
        });
      }
    }, fiveSecondsTimeout);
  } catch (error) {
    console.log("[ updateMessageHasErrorListener - seller ]", error);
  }
};
