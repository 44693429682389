<script setup>
import { ListConversationsTabs } from "@astrocentro-webapp/sellers/modules/chat/components/ListConversationsTabs";
import { GetConsultationsService } from "@astrocentro-webapp/sellers/modules/sellers/services/GetConsultationsService";
import { useConsultationsStore } from "@astrocentro-webapp/sellers/stores/consultations";
import { onMounted } from "vue";

const store = useConsultationsStore();
const getConsultationsService = new GetConsultationsService(store);

onMounted(async () => {
  await getConsultationsService.perform();
});
</script>

<template>
  <ListConversationsTabs :tabs="store.consultations" />
</template>
