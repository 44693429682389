<script setup>
import { ref, onMounted, onUpdated } from "vue";

const props = defineProps({
  icon: {
    type: String,
  },
  color: {
    type: String,
    default: "primary",
  },
  width: {
    type: Number,
    default: 24,
  },
  height: {
    type: Number,
    default: 24,
  },
});

const iconElement = ref(null);

const getIcon = async () =>
  await import(`../../../../assets/icons/${props.icon}.svg?component`);

onMounted(async () => (iconElement.value = await getIcon()));

onUpdated(async () => (iconElement.value = await getIcon()));
</script>

<template>
  <div :class="`text-${props.color} lh-1`">
    <component :width="props.width" :height="props.height" :is="iconElement" />
  </div>
</template>
