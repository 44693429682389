<script setup>
import { ref, watch } from "vue";

const emit = defineEmits(["update:modelValue"]);
const props = defineProps({
  placeholder: {
    type: String,
  },
  modelValue: {
    type: String,
  },
});

const textAreaElement = ref(null);

const handleResize = () => {
  textAreaElement.value.style.height = "38px";
  textAreaElement.value.style.height =
    textAreaElement.value.scrollHeight + "px";
};

const handleInput = (event) => {
  handleResize();
  emit("update:modelValue", event.target.value);
};

watch(
  () => props.modelValue,
  (value) => {
    if (!value) {
      textAreaElement.value.style.height = "38px";
    }
  }
);
</script>

<template>
  <div class="p-1 p-md-3 border border-gray-300 rounded">
    <textarea
      rows="1"
      class="text-area w-100 border-0"
      :placeholder="placeholder"
      :value="modelValue"
      @input="handleInput($event)"
      ref="textAreaElement"
    ></textarea>
    <slot></slot>
  </div>
</template>

<style lang="scss" scoped>
@import "@astrocentro-webapp/ui-components/assets/stylesheets/variables.scss";
@import "@astrocentro-webapp/ui-components/assets/stylesheets/vendor.scss";

.text-area {
  line-height: 1.6;
  min-height: $base-size * 4.75;
  max-height: $base-size * 15;
  margin-right: $base-size * 0.5;
  resize: none;
  overflow-y: auto;
}

.text-area:focus-visible {
  outline: none;
}

@include media-breakpoint-up(lg) {
  .text-area {
    min-height: $base-size * 4.75;
  }
}
</style>
