import { useActiveConsultationStore } from "@astrocentro-webapp/sellers/stores/activeConsultation";
import { UpdateConsultationStoreService } from "@astrocentro-webapp/sellers/modules/chat/services/UpdateConsultationStoreService";
import { PlayNewMessageNotificationAudio } from "@astrocentro-webapp/sellers/modules/commons/audios/PlayNewMessageNotificationAudio";

export const addNewMessageNotificationListener = (consultationId) => {
  const activeConsultationStore = useActiveConsultationStore();
  const updateConsultationStoreService = new UpdateConsultationStoreService();
  const activeConsultationId =
    activeConsultationStore.activeConsultation.id;
  const playNewMessageNotificationAudio = new PlayNewMessageNotificationAudio();

  if (activeConsultationId != consultationId) {
    updateConsultationStoreService.perform(consultationId, {
      hasNotification: true,
    });
    playNewMessageNotificationAudio.play();
  }
};
