import { ChannelEvents } from "@astrocentro-webapp/commons/modules/commons/events/ChannelEvents";
import { storeMessageInExternalDatabaseListener } from "@astrocentro-webapp/commons/modules/chat/listeners/storeMessageInExternalDatabaseListener";
import { SetChatChannelStatusService } from "@astrocentro-webapp/commons/modules/chat/services/SetChatChannelStatusService";
import { dispatchCustomEvent } from "@astrocentro-webapp/commons/modules/chat/listeners/dispatchCustomEvent";
import { updateConsultationsStoreListener } from "@astrocentro-webapp/sellers/modules/commons/listeners/updateConsultationsStoreListener";
import { playNewConsultationAudioListener } from "@astrocentro-webapp/sellers/modules/commons/listeners/playNewConsultationAudioListener";
import { storeConversationInLocalDatabaseListener } from "@astrocentro-webapp/sellers/modules/commons/listeners/storeConversationInLocalDatabaseListener";
import { storeMessageInLocalDatabaseListener } from "@astrocentro-webapp/sellers/modules/chat/listeners/storeMessageInLocalDatabaseListener";
import { addNewMessageNotificationListener } from "@astrocentro-webapp/sellers/modules/chat/listeners/addNewMessageNotificationListener";
import { updateMessageWasReadListener } from "@astrocentro-webapp/sellers/modules/chat/listeners/updateMessageWasReadListener";
import { inactiveConsultationListener } from "@astrocentro-webapp/sellers/modules/chat/listeners/inactiveConsultationListener";
import { handleClientCallDeviceRegisteredListener } from "@astrocentro-webapp/sellers/modules/call/listeners/handleClientCallDeviceRegisteredListener";

export class ConsultationChannelEvents extends ChannelEvents {
  #setChatChannelStatusService;

  constructor(consultationId) {
    super(`presence-consultation-${consultationId}`);
    this.#setChatChannelStatusService = new SetChatChannelStatusService(
      this.channelName
    );
  }

  bindListeners() {
    const channel = window.webSocketAdapter.subscribe(this.channelName);
    channel.unbind();

    channel
      .bind("client-chat-message-received", (event) => {
        dispatchCustomEvent("new-message-received");
        dispatchCustomEvent("client-is-typing");
        storeMessageInLocalDatabaseListener(event);
        this.trigger("client-chat-message-read", event);
        addNewMessageNotificationListener(event.consultationId);
      })
      .bind("client-chat-message-read", (message) => {
        updateMessageWasReadListener(message);
        storeMessageInExternalDatabaseListener(message);
      })
      .bind("client-chat-auto-message-read", (event) => {
        storeMessageInLocalDatabaseListener(event);
      })
      .bind("client-chat-consultation-requested", (event) => {
        storeConversationInLocalDatabaseListener(event);
        updateConsultationsStoreListener(
          event.consultation,
          event.customer,
          event.seller
        );
        playNewConsultationAudioListener();
      })
      .bind("client-is-typing", () => {
        dispatchCustomEvent("client-is-typing");
      })
      .bind("client-is-not-typing", () => {
        dispatchCustomEvent("client-is-not-typing");
      })
      .bind("client-call-consultation-requested", (event) => {
        updateConsultationsStoreListener(
          event.consultation,
          event.consultation.customer,
          event.consultation.seller
        );
        playNewConsultationAudioListener();
      })
      .bind("client-call-device-registered", async (event) => {
        handleClientCallDeviceRegisteredListener(event);
      })
      .bind("chat-consultation-finished", (event) => {
        inactiveConsultationListener(event);
      })
      .bind("call-consultation-finished", (event) => {
        inactiveConsultationListener(event);
      })
      .bind("pusher:member_added", () => {
        this.#setChatChannelStatusService.perform();
      })
      .bind("pusher:member_removed", () => {
        this.#setChatChannelStatusService.perform();
      })
      .bind("pusher:subscription_succeeded", () => {
        this.#setChatChannelStatusService.perform();
      });
  }
}
