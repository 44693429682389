<script setup>
import { ConsultationStatusBadge } from "@astrocentro-webapp/sellers/modules/chat/components/ConsultationStatusBadge";
import { ConsultationTimer } from "@astrocentro-webapp/ui-components/modules/consultation/components/ConsultationTimer";
import { SellerAvatar } from "@astrocentro-webapp/ui-components/modules/sellers/components/SellerAvatar";
import { ChatCloseButton } from "@astrocentro-webapp/ui-components/modules/chat/components/ChatCloseButton";
import { LoginService } from "@astrocentro-webapp/sellers/modules/login/services/LoginService";

const loginService = new LoginService();
const user = await loginService.getLoggedInUser();

const props = defineProps({
  recipientUser: {
    type: Object,
    default() {
      return {
        name: "",
        thumbnail: "",
      };
    },
  },
  consultation: {
    type: Object,
    default: 0,
  },
});
</script>

<template>
  <div>
    <span
      v-if="user.type == 'seller'"
      :class="`badge py-2 px-3 fs-8 d-block d-md-none ${
        props.consultation?.end_date
          ? 'bg-gray-100 text-gray-600'
          : 'bg-violet-50 text-violet'
      }`"
    >
      {{
        props.consultation?.end_date ? "Tempo total da consulta" : "Tempo atual"
      }}
      <ConsultationTimer
        class="fw-bold"
        :startDate="props.consultation?.start_date"
        :endDate="props.consultation?.end_date"
        :shouldStop="
          props.consultation?.last_status == 'in-progress' ? false : true
        "
      />
    </span>
    <div
      class="d-flex align-items-center justify-content-between px-3 py-2 p-md-3 border-bottom border-gray-100"
    >
      <div
        :class="`${
          props.recipientUser?.thumbnail
            ? 'd-flex gap-3 align-items-center'
            : ''
        }`"
      >
        <SellerAvatar
          status="available"
          :src="props.recipientUser?.thumbnail"
          size="xs"
          alignment="right"
          v-if="props.recipientUser?.thumbnail"
        />
        <div>
          <h4
            class="fs-7 fw-bold d-block d-lg-inline-block align-middle m-0 text-gray-700 me-3"
          >
            {{ props.recipientUser?.name }}
            <p
              v-if="user.type != 'customer'"
              class="mb-0 fs-9 text-primary"
              @click="$emit('open-chat-note')"
              role="button"
            >
              Clique para abrir a ficha do cliente
            </p>
          </h4>
          <ConsultationStatusBadge
            class="d-inline-block d-sm-inline-block"
            :lastStatusReason="props.consultation?.last_status_reason"
          />
        </div>
      </div>
      <div>
        <span
          v-if="user.type == 'seller'"
          :class="`badge py-2 px-3 fs-8 rounded d-none d-md-inline-block ${
            props.consultation?.end_date
              ? 'bg-gray-100 text-gray-600'
              : 'bg-violet-50 text-violet'
          }`"
        >
          {{
            props.consultation?.end_date
              ? "Tempo total da consulta"
              : "Tempo atual"
          }}
          <ConsultationTimer
            class="fw-semibold"
            :startDate="props.consultation?.start_date"
            :endDate="props.consultation?.end_date"
            :shouldStop="
              props.consultation?.last_status == 'in-progress' ? false : true
            "
          />
        </span>
        <ChatCloseButton
          v-if="props.consultation?.last_status != 'finished'"
          @click="$emit('intends-finish-consultation')"
        />
      </div>
    </div>
  </div>
</template>
