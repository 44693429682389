import { SellerServiceRepository } from "@astrocentro-webapp/sellers/modules/commons/repositories/SellerServiceRepository";
import { LoginService } from "@astrocentro-webapp/sellers/modules/login/services/LoginService";
import { FormatSellerServicesHelper } from "@astrocentro-webapp/sellers/modules/sellers/helpers/FormatSellerServicesHelper";

export class GetSellerServicesService {
  #sellerServiceRepository;
  #loginService;

  constructor() {
    this.#sellerServiceRepository = new SellerServiceRepository();
    this.#loginService = new LoginService();
  }

  /**
   * Get related logged seller services formatted as a object.
   *
   * @returns {Object}
   */
  async perform() {
    const user = await this.#loginService.getLoggedInUser();

    if (!user || !user?.seller) {
      return {};
    }

    const services = await this.#sellerServiceRepository.getServices(
      user.seller.id
    );

    return FormatSellerServicesHelper.perform(services);
  }
}
