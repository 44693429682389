import { ref } from "vue";
import { defineStore } from "pinia";

export const useConsultationsQueueStore = defineStore(
  "consultationsQueue",
  () => {
    const consultationsQueue = ref([]);

    return { consultationsQueue };
  }
);
