import { ChatRoomConversationRepository } from "@astrocentro-webapp/commons/modules/commons/repositories/ChatRoomConversationRepository";

export const storeMessageInExternalDatabaseListener = async ({
  sender,
  message,
  chatRoomId,
  consultationExternalId
}) => {
  const chatRoomConversationRepository = new ChatRoomConversationRepository(
    chatRoomId
  );
  const payload = {
    sender: sender.id,
    template: message.template,
    message: message.payload.message,
    external_id: consultationExternalId
  };

  chatRoomConversationRepository.create(payload);
};
