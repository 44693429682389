export const isFinishedPhoneConsultation = (
  activeTab,
  consultationType,
  lastStatus
) => {
  return (
    activeTab === 1 &&
    consultationType === "phone-consultation" &&
    lastStatus === "finished"
  );
};
