<script setup>
import { ConsultationStatusBadge } from "@astrocentro-webapp/sellers/modules/chat/components/ConsultationStatusBadge";
import { VIconTypeConversation } from "@astrocentro-webapp/ui-components/modules/commons/components/VIconTypeConversation";
import { getConsultationType } from "@astrocentro-webapp/sellers/modules/commons/utils/getConsultationType";
import { computedAsync } from "@vueuse/core";
import { isFinishedPhoneConsultation } from "@astrocentro-webapp/sellers/modules/commons/utils/isFinishedPhoneConsultation";

const props = defineProps({
  item: {
    type: Object,
    default: 0,
  },
  hasNewMessage: {
    type: Boolean,
  },
  activeTab: {
    type: Number,
  },
});

const consultationType = computedAsync(async () => {
  return await getConsultationType(props.item);
}, "call");
</script>

<template>
  <div
    class="px-3"
    :class="{
      'cursor-default': isFinishedPhoneConsultation(
        props.activeTab,
        consultationType,
        props.item.last_status
      ),
    }"
    @click="$emit('handleConversationClick', props.item, consultationType)"
    role="button"
  >
    <div class="d-flex justify-content-start align-items-center px-3">
      <span
        :class="`badge ${
          props.hasNewMessage ? 'bg-blue' : ''
        } me-2 rounded-circle text-white p-1`"
      >
        <span class="visually-hidden"></span>
      </span>
      <div
        class="d-flex justify-content-between w-100 border-bottom border-gray-100 py-3"
      >
        <span class="d-flex align-items-center justify-content-center">
          <VIconTypeConversation :iconType="consultationType" />
          <span class="d-grid ps-3">
            <span
              class="name d-inline-block text-truncate align-middle fw-bold"
            >
              {{ props.item.customer.name }}
            </span>
            <span class="fs-8 type-consultaton"
              >Chamado por
              <strong>{{
                consultationType === "chat-consultation" ? "Chat" : "Telefone"
              }}</strong></span
            >
          </span>
        </span>
        <span class="ms-auto d-flex align-items-center">
          <ConsultationStatusBadge
            :lastStatusReason="props.item.last_status_reason"
          />
        </span>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@astrocentro-webapp/ui-components/assets/stylesheets/variables.scss";

.cursor-default {
  cursor: default;
}

.type-consultaton {
  color: $soft-light;
}
.name {
  max-width: $base-size * 16.5;
}
.badge {
  margin-left: $base-size * -2;
}
</style>
