import Pusher from "pusher-js";

export class WebSocketAdapter {
  /**
   * Returns a Pusher instance
   *
   * @param {string} accessToken
   * @returns {Pusher}
   */
  getInstance(accessToken) {
    return new Pusher(import.meta.env.VITE_PUSHER_KEY, {
      appId: import.meta.env.VITE_PUSHER_APP_ID,
      secret: import.meta.env.VITE_PUSHER_APP_SECRET,
      cluster: import.meta.env.VITE_PUSHER_CLUSTER,
      channelAuthorization: {
        endpoint: `${
          import.meta.env.VITE_ASTROCENTRO_API_URL
        }/broadcasting/auth`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    });
  }
}
