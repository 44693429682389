<script setup>
import { Input } from "@astrocentro-webapp/ui-components/modules/commons/components/Input";
import { InputPassword } from "@astrocentro-webapp/ui-components/modules/commons/components/InputPassword";

const props = defineProps({
  id: {
    type: String,
  },
  name: {
    type: String,
  },
  label: {
    type: String,
  },
  placeholder: {
    type: String,
  },
  type: {
    type: String,
    default: "text",
    validator(value) {
      return ["text", "password", "email"].includes(value);
    },
  },
  required: {
    type: Boolean,
    default: false,
  },
  isInvalid: {
    type: Boolean,
    default: false,
  },
  invalidFeedback: {
    type: String,
    default: "",
  },
});

const inputTypes = {
  text: Input,
  email: Input,
  password: InputPassword,
};
</script>

<template>
  <component
    :is="inputTypes[props.type]"
    :id="props.id"
    :name="props.name"
    :label="props.label"
    :placeholder="props.placeholder"
    :required="props.required"
    :isInvalid="props.isInvalid"
    :invalidFeedback="props.invalidFeedback"
  />
</template>
