import { ConsultationChannelEvents } from "@astrocentro-webapp/sellers/modules/chat/events/ConsultationChannelEvents";
import { useConsultationsStore } from "@astrocentro-webapp/sellers/stores/consultations";
import { DateHelper } from "@astrocentro-webapp/commons/modules/commons/helpers/DateHelper";
import { PlayStopNewConsultationAudio } from "@astrocentro-webapp/sellers/modules/commons/audios/PlayStopNewConsultationAudio.js";

/**
 * Finish a chat consultation by seller timeout or customer abandonment
 *
 * @param {number} consultationId
 * @param {string} reason
 * @returns {Object}
 */
export const finishConsultationsStoreListener = async (
  consultationId,
  reason
) => {
  const consultationsStore = useConsultationsStore();
  const dateHelper = new DateHelper();
  const status = "finished";
  const endDate = dateHelper.format(null, "yyyy-MM-dd HH:mm:ss");
  const newConsultationAudio = new PlayStopNewConsultationAudio();
  newConsultationAudio.stop();
  const consultationChannelEvents = new ConsultationChannelEvents(consultationId);
  consultationChannelEvents.unsubscribe();

  consultationsStore.$patch(async (state) => {
    let activeCalls = await state.consultations["Chamadas Ativas"];
    let inactiveCalls = await state.consultations["Chamadas Inativas"];

    activeCalls = activeCalls.filter((item) => {
      if (item.id == consultationId) {
        item.last_status = status;
        item.last_status_reason = reason;
        item.end_date = endDate;
        inactiveCalls.push(item);
      }

      return item.id != consultationId;
    });

    state.consultations = {
      "Chamadas Ativas": activeCalls,
      "Chamadas Inativas": inactiveCalls,
    };
  });
};
