<script setup>
import { ref } from "vue";
import { VIcon } from "@astrocentro-webapp/ui/modules/commons/components/VIcon";
import { Input } from "@astrocentro-webapp/ui-components/modules/commons/components/Input";

const inputType = ref("password");
const props = defineProps({
  id: {
    type: String,
  },
  name: {
    type: String,
  },
  label: {
    type: String,
  },
  placeholder: {
    type: String,
  },
  required: {
    type: Boolean,
    default: false,
  },
  isInvalid: {
    type: Boolean,
    default: false,
  },
  invalidFeedback: {
    type: String,
    default: "",
  },
});

const handleClick = () => {
  let newInputType = "text";
  if (inputType.value === "text") newInputType = "password";
  inputType.value = newInputType;
};
</script>

<template>
  <div class="position-relative">
    <Input
      :type="inputType"
      :id="props.id"
      :placeholder="props.placeholder"
      :name="props.name"
      :label="props.label"
      :required="props.required"
      :isInvalid="props.isInvalid"
      :invalidFeedback="props.invalidFeedback"
    >
      <VIcon
        color="dark"
        class="position-absolute eye-icon"
        :width="24"
        :height="24"
        :icon="inputType === 'text' ? 'eye' : 'eye-slash'"
        @click="handleClick"
      />
    </Input>
  </div>
</template>

<style lang="scss">
@import "@astrocentro-webapp/ui-components/assets/stylesheets/variables.scss";

.eye-icon {
  padding: $base-size 0 $base-size ($base-size * 1.5);
  right: $base-size * 2;
  transform: translateY(calc(-50% - ($base-size * 3)));
  border-left: 1px solid $input-border-color;
  cursor: pointer;
}
</style>
