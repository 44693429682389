import { ref } from "vue";
import { defineStore } from "pinia";

export const useActiveConsultationStore = defineStore(
  "activeConsultation",
  () => {
    const activeConsultation = ref({});

    return { activeConsultation };
  }
);
