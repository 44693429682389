export class ChatEvents {
  /**
   * @type {Map}
   * @public
   */
  events;

  /**
   * @type {boolean}
   * @protected
   */
  _subscribed;

  constructor() {
    this.events = new Map();
  }

  /**
   * Subscribe to the events
   */
  subscribe() {
    if (!this._subscribed) {
      this.events.set("message-sent", new Set([]));

      this._subscribed = true;
    }
  }

  /**
   * Unsubscribe from the specified event
   * @param {string} event the event name
   * @returns {boolean}
   */
  unsubscribe(event) {
    return this.events.delete(event);
  }

  /**
   * Emits an event
   * @param {string} event the event name
   * @param  {object} data 
   * @returns {any} the data associated with the event
   */
  emit(event, ...data) {
    return this.events.get(event).forEach((listener) => listener(...data));
  }
}
