<script setup>
import { VModal } from "@astrocentro-webapp/ui-components/modules/commons/components/VModal";
import { VButton } from "@astrocentro-webapp/ui/modules/commons/components/VButton";
import { VDefaultAvatar } from "@astrocentro-webapp/ui-components/modules/commons/components/VDefaultAvatar";
import { ref, watch } from "vue";
import { useConsultationsStore } from "@astrocentro-webapp/sellers/stores/consultations";

const emit = defineEmits(["modal-closed"]);
const onModalClosed = () => emit("modal-closed");
const consultation = ref({});
const consultationsStore = useConsultationsStore();

const props = defineProps({
  consultationId: {
    type: String,
  },
  open: {
    type: Boolean,
  },
});

watch(
  () => props.consultationId,
  (consultationId) => {
    consultationId && setConsultation(consultationId);
  }
);

const setConsultation = async (consultationId) => {
  let consultations = [];
  const activeConsultations = await consultationsStore.consultations[
    "Chamadas Ativas"
  ];
  const inactiveConsultations = await consultationsStore.consultations[
    "Chamadas Inativas"
  ];

  activeConsultations &&
    (consultations = consultations.concat(activeConsultations));
  inactiveConsultations &&
    (consultations = consultations.concat(inactiveConsultations));

  let [consultationItem] = consultations.filter(
    (item) => item.id == consultationId
  );

  consultation.value = consultationItem;
};
</script>

<template>
  <VModal
    :open="props.open"
    id="modal-chat-succeded"
    @modal-closed="onModalClosed"
  >
    <div>
      <VDefaultAvatar size="sm" class="mb-5" /><span class="ms-3 fw-bold">{{
        consultation?.customer?.name
      }}</span>
      <h2 class="mb-3 fw-bold">Atendimento concluído &#127881;</h2>
      <p>Você fez um ótimo trabalho continue assim!</p>
      <div
        class="d-flex justify-content-between my-5 border-gray-100 border-top pt-3"
      >
        <span class="text-gray-400">Tempo de atendimento</span>
        <span class="fw-semibold">{{
          `${consultation?.duration?.minutes}:${consultation?.duration?.seconds} min`
        }}</span>
      </div>
      <div class="d-grid">
        <VButton class="btn-lg btn-primary" @click="onModalClosed">
          Obrigado! Ir para o proximo
        </VButton>
        <VButton
          class="btn-lg mt-3 btn-outline-gray-300 text-gray-700"
          @click="onModalClosed"
        >
          Fechar
        </VButton>
      </div>
    </div>
  </VModal>
</template>
