<script setup>
import { ref } from "vue";
import {
  computedAsync,
  useEventListener,
  useDebounceFn,
  computedEager,
} from "@vueuse/core";

import { VSwitch } from "@astrocentro-webapp/ui-components/modules/commons/components/VSwitch";
import { GetSellerServicesService } from "@astrocentro-webapp/sellers/modules/sellers/services/GetSellerServicesService";
import { UpdateSellerServiceStatusService } from "@astrocentro-webapp/sellers/modules/sellers/services/UpdateSellerServiceStatusService";
import { LoginService } from "@astrocentro-webapp/sellers/modules/login/services/LoginService";
import { useServicesStore } from "@astrocentro-webapp/sellers/stores/services";

const store = useServicesStore();
const getSellerServicesService = new GetSellerServicesService();
const updateSellerServiceStatusService = new UpdateSellerServiceStatusService(
  store
);

const isDisabled = ref({
  "chat-consultation": false,
  "phone-consultation": false,
  "keep-offline": false,
});

const statuses = computedAsync(
  async () => {
    const services = await store.services;

    return {
      "chat-consultation": services["chat-consultation"].connected,
      "phone-consultation": services["phone-consultation"].connected,
    };
  },
  {
    "chat-consultation": false,
    "phone-consultation": false,
  }
);

const isKeepOfflineChecked = computedEager(
  () =>
    !statuses.value["chat-consultation"] &&
    !statuses.value["phone-consultation"]
);

const loginService = new LoginService();
const user = await loginService.getLoggedInUser();

const handleSwitchChange = async (service) => {
  try {
    Object.keys(isDisabled.value).forEach((service) => {
      isDisabled.value[service] = true;
    });

    if (service === "keep-offline") {
      const services = {
        "chat-consultation": false,
        "phone-consultation": false,
      };

      if (isKeepOfflineChecked.value) {
        services["chat-consultation"] = true;
        services["phone-consultation"] = true;
      }

      await updateSellerServiceStatusService.perform(services);
      Object.keys(isDisabled.value).forEach((service) => {
        isDisabled.value[service] = false;
      });

      return;
    }

    await updateSellerServiceStatusService.perform({
      [service]: !statuses.value[service],
    });
  } finally {
    Object.keys(isDisabled.value).forEach((service) => {
      isDisabled.value[service] = false;
    });
  }
};

const handleVisibilityChange = useDebounceFn(async () => {
  if (document.visibilityState === "visible") {
    try {
      Object.keys(isDisabled.value).forEach((service) => {
        isDisabled.value[service] = true;
      });
      store.services = (async () => getSellerServicesService.perform())();
    } finally {
      Object.keys(isDisabled.value).forEach((service) => {
        isDisabled.value[service] = false;
      });
    }
  }
}, 3000);

useEventListener(document, "visibilitychange", handleVisibilityChange);
</script>

<template>
  <div class="d-lg-flex">
    <VSwitch
      :checked="statuses['chat-consultation']"
      :disabled="isDisabled['chat-consultation']"
      label="Online por Chat"
      @click="handleSwitchChange('chat-consultation')"
    />
    <VSwitch
      v-if="user.seller?.tph_wp_account_seller?.attends_in_new_call"
      :checked="statuses['phone-consultation']"
      :disabled="isDisabled['phone-consultation']"
      label="Online por Telefone"
      @click="handleSwitchChange('phone-consultation')"
    />
    <VSwitch
      :checked="isKeepOfflineChecked"
      :disabled="isDisabled['keep-offline']"
      label="Me manter Offline"
      @click="handleSwitchChange('keep-offline')"
    />
  </div>
</template>
