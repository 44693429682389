import { ConsultationChannelEvents } from "@astrocentro-webapp/sellers/modules/chat/events/ConsultationChannelEvents";
import { ConsultationRepository } from "@astrocentro-webapp/sellers/modules/commons/repositories/ConsultationRepository";
import { DateHelper } from "@astrocentro-webapp/sellers/modules/commons/helpers/DateHelper";
import { UpdateConsultationStoreService } from "@astrocentro-webapp/sellers/modules/chat/services/UpdateConsultationStoreService";

export class AcceptCallConsultationService {
  createConversationService;
  dateHelper;
  updateConsultationStoreService;
  conversationRepository;
  consultationsStore;

  constructor() {
    this.dateHelper = new DateHelper();
    this.updateConsultationStoreService = new UpdateConsultationStoreService();
  }

  /**
   * Accept a chat consultation request
   *
   * @param {Number} consultationId
   * @returns {Object}
   */
  async perform(consultationId) {
    const status = "in-progress";
    const statusReason = "accepted-by-seller";
    const startDate = this.dateHelper.format(new Date(), "yyyy-MM-dd HH:mm:ss");
    const consultationChannelEvents = new ConsultationChannelEvents(
      consultationId
    );
    const consultationRepository = new ConsultationRepository();

    consultationChannelEvents.subscribe();

    await this.updateConsultationStoreService.perform(consultationId, {
      last_status: status,
      last_status_reason: statusReason,
      start_date: startDate,
    });

    return consultationRepository.update(consultationId, {
      last_status: status,
      last_status_reason: statusReason,
    });
  }
}
