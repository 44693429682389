<script setup>
const props = defineProps({
  name: {
    type: String,
    default: "A",
  },
  size: {
    type: String,
    default: "xs",
  },
});

const color = (name) => {
  return name.length % 5;
}

</script>

<template>
  <span
    :class="`rounded-circle avatar-${props.size} avatar-color-${color(props.name)} d-inline-flex justify-content-center align-items-center`"
    >{{ props.name.substring(0, 1) }}</span
  >
</template>

<style lang="scss" scoped>
@import "@astrocentro-webapp/ui-components/assets/stylesheets/variables.scss";

.avatar-color-0{
  color: #f42f28;
  background-color: rgba(#f42f28, 0.05);
}

.avatar-color-1 {
  color: #528089;
  background-color: rgba(#528089, 0.1);
}

.avatar-color-2 {
  color: #88471d;
  background-color: rgba(#88471d, 0.1);
}

.avatar-color-3 {
  color: #7fc58f;
  background-color: rgba(#7fc58f, 0.1);
}

.avatar-color-4 {
  color: #b08fc4;
  background-color: rgba(#b08fc4, 0.1);
}
</style>
