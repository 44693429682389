import Dexie from "dexie";

export class DatabaseAdapter {
  database;

  constructor() {
    if (DatabaseAdapter._instance) {
      return DatabaseAdapter._instance;
    }

    this.database = new Dexie("astrocentro-sellers");
    this.#migrate();
    this.#listen();

    DatabaseAdapter._instance = this;
  }

  #migrate() {
    this.database.version(1).stores({
      conversations: "++id, [date+chatRoomId]",
    });
  }

  #listen() {
    // https://dexie.org/docs/Dexie/Dexie.on.versionchange#sample
    this.database.on("versionchange", () => window.location.reload())
  }

  /**
   * Open the current database.
   *
   * @returns {Promise}
   */
  async open() {
    return this.database.open();
  }

  /**
   * Deletes the current database.
   *
   * @returns {Promise}
   */
  async delete() {
    return this.database.delete();
  }
}
