<script setup>
import { RouterView } from "vue-router";
import { watchOnce } from "@vueuse/core";

import { VToast } from "@astrocentro-webapp/ui-components/modules/commons/components/VToast";
import { ConsultationChannelEvents } from "@astrocentro-webapp/sellers/modules/chat/events/ConsultationChannelEvents";
import { useConsultationsStore } from "@astrocentro-webapp/sellers/stores/consultations";
import { useServicesStore } from "@astrocentro-webapp/sellers/stores/services";

const store = useConsultationsStore();
const servicesStore = useServicesStore();

watchOnce(
  () => store.consultations,
  async (consultations) => {
    const activeConsultations = await consultations["Chamadas Ativas"];
    const sellerServices = await servicesStore.services;
    // TODO: Move below code to a generic package ie: commons
    activeConsultations.forEach((activeConsultation) => {
      const isCallConsultation =
        sellerServices &&
        activeConsultation.seller_service_id ===
          sellerServices["phone-consultation"].id;

      if (
        (isCallConsultation &&
          activeConsultation.last_status === "requested") ||
        activeConsultation.last_status === "in-progress"
      ) {
        const consultationChannelEvents = new ConsultationChannelEvents(
          activeConsultation.id
        );
        consultationChannelEvents.subscribe();
      }
    });
  }
);
</script>

<template>
  <RouterView v-slot="{ Component }">
    <template v-if="Component">
      <Suspense>
        <component :is="Component"></component>
      </Suspense>
    </template>
  </RouterView>
  <VToast></VToast>
</template>

<style lang="scss">
@import "@astrocentro-webapp/ui-components/assets/stylesheets/app.scss";
</style>
