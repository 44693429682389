import { useConsultationsStore } from "@astrocentro-webapp/sellers/stores/consultations";

export class UpdateConsultationStoreService {
  /**
   * Update the hasNotification status of a consultation.
   *
   * @param {number} consultationId
   * @param {object} data
   *
   */
  async perform(consultationId, data) {
    const store = useConsultationsStore();
    const activeConsultations = await store.consultations["Chamadas Ativas"];
    const inactiveConsultations = await store.consultations[
      "Chamadas Inativas"
    ];

    const activeConsultation = activeConsultations.map((consultation) => {
      if (consultation.id === consultationId) {
        consultation = { ...consultation, ...data };
      }

      return consultation;
    });

    const inactiveConsultation = inactiveConsultations.map((consultation) => {
      if (consultation.id === consultationId) {
        consultation = { ...consultation, ...data };
      }

      return consultation;
    });

    store.$patch(async (state) => {
      state.consultations = {
        "Chamadas Ativas": activeConsultation,
        "Chamadas Inativas": inactiveConsultation,
      };
    });
  }
}
