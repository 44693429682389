<script setup>
import { VIcon } from "@astrocentro-webapp/ui-components/modules/commons/components/VIcon";

const props = defineProps({
  hasIcon: {
    type: Boolean,
    default: true,
  },
  variant: {
    type: String,
    default: "warning",
  },
});

const colors = {
  warning: {
    bgColor: "warning-50",
    borderColor: "warning-300",
    textColor: "warning-700",
    icon: "warning-icon",
  },
  light: {
    bgColor: "gray-50",
    borderColor: "gray-300",
    textColor: "gray-700",
    icon: "warning-icon",
  },
  danger: {
    bgColor: "warning-50",
    borderColor: "error-700",
    textColor: "error-700",
    icon: "warning-icon",
  },
};
</script>

<template>
  <div
    :class="`alert text-${colors[variant].textColor} border-${colors[variant].borderColor} bg-${colors[variant].bgColor} alert-dismissible fade show`"
    role="alert"
  >
    <VIcon
      v-if="props.hasIcon"
      :color="colors[variant].textColor"
      :width="20"
      :height="20"
      :icon="colors[variant].icon"
      class="mb-3"
    />
    <p :class="`fw-bold fs-8 mb-1 text-${colors[variant].textColor}`">
      <slot name="header"></slot>
    </p>
    <p :class="`fs-8 mb-0 text-${colors[variant].textColor}`">
      <slot name="main"></slot>
    </p>
    <slot name="footer"></slot>
    <button
      type="button"
      :class="`position-absolute top-0 end-0 border-0 p-3 bg-transparent`"
      data-bs-dismiss="alert"
      aria-label="Close"
      @click="$emit('close')"
    >
      <VIcon
        :color="colors[variant].textColor"
        :width="20"
        :height="20"
        icon="close"
      />
    </button>
  </div>
</template>
