import { ref } from "vue";
import { defineStore } from "pinia";

import { GetSellerServicesService } from "@astrocentro-webapp/sellers/modules/sellers/services/GetSellerServicesService";

export const useServicesStore = defineStore("services", () => {
  const getSellerServiceStatusService = new GetSellerServicesService();
  const services = ref({});

  services.value = (async () => getSellerServiceStatusService.perform())();

  return { services };
});