export class PlayNewMessageNotificationAudio {
  #audio;

  constructor() {
    this.#audio = new Audio(
      "https://cdn.astrocentro.com.br/webapp/sounds/pop.mp3"
    );
  }
  
  play() {
    try {
      this.#audio.play();
    } catch (error) {
      console.log('error', error);
    }
  }
}