import { BaseRepository } from "@astrocentro-webapp/sellers/modules/commons/repositories/BaseRepository";

export class SellerServiceRepository extends BaseRepository {
  constructor() {
    super();

    this.resource = "services";
  }

  /**
   * @typedef {Object} Service
   * @property {number} id
   * @property {number} seller_id
   * @property {string} service
   * @property {number} price
   * @property {string} status
   * @property {boolean} connected
   * @property {Date} created_at
   * @property {number} updated_at
   */

  /**
   * Get related services of a seller
   * @param {number} sellerId
   *
   * @returns {Service[]}
   */
  async getServices(sellerId) {
    const { data } = await this.httpClient.get(
      `v1/sellers/${sellerId}/services`
    );

    return data;
  }

  /**
   * Update related servics of a seller
   * @param sellerId
   * @param payload
   * @returns {Promise<undefined>}
   */
  async updateServices(sellerId, payload) {
    const { data } = await this.httpClient.put(
        `v1/sellers/${sellerId}/services`,
        payload
    );

    return data;
  }
}
