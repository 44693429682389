<script setup>
import { ref, onMounted } from "vue";
import { VButton } from "@astrocentro-webapp/ui/modules/commons/components/VButton";
import { VModal } from "@astrocentro-webapp/ui-components/modules/commons/components/VModal";
import { getDevices, updateDevice } from "@astrocentro-webapp/astrocentro/modules/commons/utils/handleDevice";

const emit = defineEmits(["modal-closed"]);
const inputDevices = ref([]);
const outputDevices = ref([]);
const selectedInputDevice = ref('default');
const selectedOutputDevice = ref('default');

const props = defineProps({
  open: {
    type: Boolean,
    default: false,
  },
  callClient: {
    type: Object,
    required: false
  }
});

onMounted(async () => {
  const devices = await getDevices();
  inputDevices.value = devices[0];
  outputDevices.value = devices[1];
});

const setDevice = async (type) => {
  const selectedDevice = type === 'input' ? selectedInputDevice.value : selectedOutputDevice.value;
  await updateDevice(props.callClient, type, selectedDevice)
}

</script>

<template>
  <VModal
    :open="props.open"
    id="pre-consultation-modal"
    @modal-closed="$emit('modal-closed')"
    class="text-center"
  >
    <div class="p-md-5 pt-md-0 mx-md-5">
      <div class="mb-5">
        <div>
          <div class="my-4 d-flex flex-column text-start text-muted">
            <label for="inputDevice">Microfone:</label>
            <select class="form-select" id="inputDevice" v-model="selectedInputDevice" @change="setDevice('input')">
              <option v-for="device in inputDevices" :key="device.deviceId" :value="device.deviceId">
                {{ device.label }}
              </option>
            </select>
          </div>

          <div class="my-4 d-flex flex-column text-start text-muted">
            <label for="outputDevice">Auto-falante:</label>
            <select class="form-select" id="outputDevice" v-model="selectedOutputDevice" @change="setDevice('output')">
              <option v-for="device in outputDevices" :key="device.deviceId" :value="device.deviceId">
                {{ device.label }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="d-grid mt-5">
        <VButton
          class="btn-lg btn-primary mb-3 fw-bold"
          @click="$emit('modal-closed')"
          >Fechar</VButton
        >
      </div>
    </div>
  </VModal>
</template>
