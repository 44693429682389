<script setup>
import { onMounted, ref } from "vue";
import { Tooltip } from "bootstrap";

const props = defineProps({
  placement: {
    type: String,
  },
  title: {
    type: String,
  },
});
const tooltip = ref(null);

onMounted(() => {
  new Tooltip(tooltip.value);
});
</script>

<template>
  <div
    ref="tooltip"
    data-bs-toggle="tooltip"
    :data-bs-placement="props.placement"
    :title="props.title"
  >
    <slot></slot>
  </div>
</template>
