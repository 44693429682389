import { ChannelEvents } from "@astrocentro-webapp/commons/modules/commons/events/ChannelEvents";
import { ConsultationChannelEvents } from "@astrocentro-webapp/sellers/modules/chat/events/ConsultationChannelEvents";
import { finishConsultationsStoreListener } from "@astrocentro-webapp/sellers/modules/commons/listeners/finishConsultationsStoreListener";
import { showStillAvailableModalListener } from "@astrocentro-webapp/sellers/modules/chat/listeners/showStillAvailableModalListener";

export class SellerChannelEvents extends ChannelEvents {
  constructor(sellerId) {
    super(`private-seller-${sellerId}`);
  }

  bindListeners() {
    const channel = window.webSocketAdapter.subscribe(this.channelName);
    channel.unbind();

    channel
      .bind("chat-consultation-requested", (event) => {
        const consultationChannelEvents = new ConsultationChannelEvents(
          event.consultation.id
        );
        consultationChannelEvents.subscribe();
      })
      .bind("chat-consultation-timed-out", (event) => {
        finishConsultationsStoreListener(event.consultation.id, "timeout");
        showStillAvailableModalListener();
      })
      .bind("chat-consultation-given-up", (event) => {
        finishConsultationsStoreListener(
          event.consultation.id,
          "given-up-by-customer"
        );
        showStillAvailableModalListener();
      })
      .bind("call-consultation-requested", (event) => {
        const consultationChannelEvents = new ConsultationChannelEvents(
          event.consultation.id
        );
        consultationChannelEvents.subscribe();
      })
      .bind("call-consultation-timed-out", (event) => {
        finishConsultationsStoreListener(event.consultation.id, "timeout");
        showStillAvailableModalListener();
      });
  }
}
