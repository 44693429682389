<script setup>
import { DateHelper } from "@astrocentro-webapp/commons/modules/commons/helpers/DateHelper";
import { VAvatar } from "@astrocentro-webapp/ui-components/modules/commons/components/VAvatar";
import { Input } from "@astrocentro-webapp/ui-components/modules/commons/components/Input";
import Search from "@astrocentro-webapp/ui-components/assets/icons/search.svg?component";
import AstrocentroWorkstationIcon from "@astrocentro-webapp/ui/assets/icons/astrocentro-workstation.svg?component";
import { reactive, onMounted } from "vue";
import { ConsultationRepository } from "@astrocentro-webapp/sellers/modules/commons/repositories/ConsultationRepository";
import { LoginService } from "@astrocentro-webapp/sellers/modules/login/services/LoginService";
import { useRouter } from "vue-router";

const dateHelper = new DateHelper();
const consultationRepository = new ConsultationRepository();
const router = useRouter();
const loginService = new LoginService();
const user = await loginService.getLoggedInUser();

const state = reactive({
  currentPage: 1,
  perPage: 10,
  consultations: [],
  totalConsultations: 0,
  totalPages: 0,
  name: null,
});
const handleSearch = async () => {
  state.currentPage = 1;
  await retrieveList();
};

const handleOpenChat = async (id) => {
  router.push(`/consultations/history/${id}`);
};

onMounted(async () => {
  await retrieveList();
});

const retrieveList = async () => {
  const { data, total, last_page } = await consultationRepository.getHistory(
    state.currentPage,
    state.perPage,
    state.name,
    user.seller.id,
  );
  state.consultations = data;
  state.totalConsultations = total;
  state.totalPages = last_page;
};

const handleChangePage = async (direction = 0) => {
  if (
    !(direction == 1 && state.currentPage >= state.totalPages) &&
    !(direction == -1 && state.currentPage <= 1)
  )
    state.currentPage = state.currentPage + direction;
  await retrieveList();
};

const formatDuration = (start, end) => {
  const { minutes, seconds } = dateHelper.intervalToDuration({
    start: dateHelper.get(start),
    end: dateHelper.get(end),
  });
  return `${minutes}:${("0" + seconds).slice(-2)} min`;
};
</script>

<template>
  <div class="my-4 bg-white border border-gray-200 rounded">
    <div class="p-3 border-bottom">
      <h3>
        Histórico de consultas
        <span
          v-if="state.totalConsultations > 0"
          class="badge rounded-pill bg-blue-10 text-blue ms-2"
          >{{ state.totalConsultations }} consultas</span
        >
      </h3>
      <p class="m-0 fs-8">
        Aqui você pode ver suas consultas e chamadas ao longo do tempo
      </p>
    </div>
    <div class="border-bottom">
      <div class="col-12 col-sm-6">
        <div class="input-group input-group-sm p-3">
          <span class="input-group-text bg-white"
            ><Search
              width="20"
              height="20"
              class="text-gray-500"
              @click="handleSearch"
              role="button"
          /></span>
          <input
            v-model="state.name"
            type="text"
            class="form-control"
            placeholder="Procurar"
          />
        </div>
      </div>
    </div>
    <div v-if="state.totalConsultations == 0">
      <div class="d-flex align-items-center justify-content-center">
        <div class="text-center py-5 px-3">
          <AstrocentroWorkstationIcon class="mb-5" />
          <h2>Você ainda não tem um histórico</h2>
          <p class="fs-8 mb-0">
            O seu histórico de consultas por chat aparecerá aqui
          </p>
        </div>
      </div>
    </div>
    <div v-else>
      <table class="table align-middle mb-0 border-gray">
        <thead class="table-light">
          <tr>
            <th
              scope="col"
              class="fw-semibold fs-9 text-gray-500 px-2 py-3 p-sm-3 d-none d-sm-table-cell"
            >
              Data
            </th>
            <th scope="col" class="fw-semibold fs-9 text-gray-500 px-2 py-3 p-sm-3">
              Clientes
            </th>
            <th
              scope="col"
              class="fw-semibold fs-9 text-gray-500 px-2 py-3 p-sm-3 d-none"
            >
              Valor da consulta
            </th>
            <th
              scope="col"
              class="fw-semibold fs-9 text-gray-500 px-2 py-3 p-sm-3 d-none d-sm-table-cell"
            >
              Prévia de conteúdo
            </th>
            <th scope="col" class="fw-semibold fs-9 text-gray-500 px-2 py-3 p-sm-3">
              Tempo <span class="d-none d-sm-table-cell">da consulta</span>
            </th>
          </tr>
        </thead>
        <tbody class="border-top-0">
          <tr
            v-for="consultation in state.consultations"
            @click="handleOpenChat(consultation.id)"
          >
            <td class="fs-8 px-2 py-3 p-sm-3 d-none d-sm-table-cell">
              {{ dateHelper.format(consultation.start_date, "dd MMMM yyy") }}
            </td>
            <td class="fs-8 px-2 py-3 p-sm-3">
              <div class="d-flex align-items-center">
                <VAvatar variant="xs" class="bg-gray-200 me-2" />
                <div>
                  <p class="fs-8 fw-semibold mb-0">
                    {{ consultation.customer?.name }}
                  </p>
                </div>
              </div>
            </td>
            <td class="fs-8 px-2 py-3 p-sm-3 d-none">
              <span
                class="badge rounded-pill bg-white border border-success text-success-700"
                >R$ {{ consultation.price ? consultation.price : 0 }}</span
              >
            </td>
            <td
              class="fs-8 px-2 py-3 p-sm-3 text-decoration-underline d-none d-sm-table-cell"
            >
              Histórico
            </td>
            <td class="fs-8 px-2 py-3 p-sm-3">
              <span class="badge rounded-pill bg-light text-gray-900">{{
                formatDuration(consultation.start_date, consultation.end_date)
              }}</span>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="d-flex justify-content-between align-items-center px-3 py-2">
        <p class="fs-8 mb-0">
          Página {{ state.currentPage }} de {{ state.totalPages }}
        </p>
        <div class="">
          <button
            class="btn btn-sm btn-outline-gray-300 text-gray-900 me-2"
            @click="handleChangePage(-1)"
            :disabled="state.currentPage <= 1 ? true : false"
          >
            Anterior
          </button>
          <button
            class="btn btn-sm btn-outline-gray-300 text-gray-900"
            @click="handleChangePage(1)"
            :disabled="state.currentPage >= state.totalPages ? true : false"
          >
            Próxima
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.table {
  tbody tr:hover {
    background-color: $gray-50;
    cursor: pointer;
  }
}
@include media-breakpoint-down(sm) {
  .text-truncate {
    width: $base-size * 20;
  }
}

</style>
