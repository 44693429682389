import { WebSocketAdapter } from "@astrocentro-webapp/sellers/modules/commons/adapters/WebSocketAdapter";
import { SellerChannelEvents } from "@astrocentro-webapp/sellers/modules/commons/events/SellerChannelEvents";
import { ChatEvents } from "@astrocentro-webapp/sellers/modules/chat/events/ChatEvents";
import { LoginService } from "@astrocentro-webapp/sellers/modules/login/services/LoginService";

/**
 * Create a websocket connection and connect to the seller's channel
 */
export const webSocketConnect = async () => {
  if (localStorage.accessToken) {
    const loginService = new LoginService();
    const webSocketAdapter = new WebSocketAdapter();
    const chatEvents = new ChatEvents();
    const user = await loginService.getLoggedInUser();
    const sellerChannelEvents = new SellerChannelEvents(user.seller.id);
    window.webSocketAdapter = webSocketAdapter.getInstance(
      localStorage.accessToken
    );

    chatEvents.subscribe();
    sellerChannelEvents.subscribe();
  }
};
