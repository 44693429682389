import { DateHelper } from "@astrocentro-webapp/commons/modules/commons/helpers/DateHelper";
import { useConsultationsStore } from "@astrocentro-webapp/sellers/stores/consultations";

export class ClearFinishedConsultationsHistoryService {
  #consultationsStore;
  #dateHelper;

  constructor() {
    this.#dateHelper = new DateHelper();
    this.#consultationsStore = useConsultationsStore();
  }

  /**
   * Clear finished consultations history.
   *
   */
  async perform() {
    // TODO: Rename storage key to respect standards
    localStorage.setItem(
      "clear-history-date",
      this.#dateHelper.get(null).toISOString()
    );
    this.#consultationsStore.$patch(async (state) => {
      let activeCalls = await state.consultations["Chamadas Ativas"];

      state.consultations = {
        "Chamadas Ativas": activeCalls,
        "Chamadas Inativas": [],
      };
    });
  }
}
