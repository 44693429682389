<script setup>
import { ref } from "vue";
import { useRoute, RouterLink } from "vue-router";
import { TheHeader } from "@astrocentro-webapp/sellers/modules/commons/components/TheHeader";
import { Notes } from "@astrocentro-webapp/sellers/modules/commons/components/Notes";
import { ChatConversation } from "@astrocentro-webapp/ui-components/modules/chat/components/ChatConversation";
import { LoginService } from "@astrocentro-webapp/sellers/modules/login/services/LoginService";
import { ConsultationRepository } from "@astrocentro-webapp/sellers/modules/commons/repositories/ConsultationRepository";
import { FormatConversationMessagesHelper } from "@astrocentro-webapp/commons/modules/chat/helpers/FormatConversationMessagesHelper";
import { DateHelper } from "@astrocentro-webapp/commons/modules/commons/helpers/DateHelper";
import { VIcon } from "@astrocentro-webapp/ui-components/modules/commons/components/VIcon";

const loginService = new LoginService();
const user = await loginService.getLoggedInUser();
const route = useRoute();
const consultationRepository = new ConsultationRepository();
const dateHelper = new DateHelper();
const recipientUserType = user.type == "seller" ? "customer" : "seller";
const isChatNoteOpen = ref(false);
var conversations = [];

var consultation = await consultationRepository.get(
  `/${route.params.consultationId}?relationships[]=chatHistory&relationships[]=customer.user&relationships[]=seller`
);

if (consultation.chat_history.length > 0) {
  consultation.chat_history[0].date = dateHelper.format(
    consultation.start_date,
    "yyyy-MM-dd"
  );
  const messages = FormatConversationMessagesHelper.perform(
    consultation.chat_history[0],
    consultation
  );
  conversations = [
    {
      date: consultation.chat_history[0].date,
      messages,
      chatRoomId: consultation.chat_room_id,
    },
  ];
}
</script>

<template>
  <main class="d-flex flex-column">
    <TheHeader />
    <div class="container-fluid h-100 bg-gray-100">
      <div class="row justify-content-center h-100">
        <div class="col-sm-11 mt-2 mt-md-5 mb-5">
          <div class="d-flex justify-content-between">
            <div class="d-none d-md-flex">
              <RouterLink to="/">
                <VIcon
                  icon="home"
                  :width="20"
                  :height="20"
                  viewBox="0 0 20 20"
                />
              </RouterLink>
              <VIcon
                icon="arrow-simple-right"
                :width="6"
                :height="10"
                viewBox="0 0 6 10"
                class="mx-4"
              />
              <RouterLink
                to="/consultations/history"
                class="text-gray-700 text-decoration-none"
              >
                <span class="fs-8">Histórico</span>
              </RouterLink>
              <VIcon
                icon="arrow-simple-right"
                :width="6"
                :height="10"
                viewBox="0 0 6 10"
                class="mx-4"
              />
              <span class="fs-8 fw-semibold"
                >Atendimento {{ consultation.customer.user.name }}</span
              >
            </div>
            <RouterLink
              to="/consultations/history"
              class="fs-8 text-primary d-flex text-decoration-none"
            >
              <VIcon
                icon="arrow-left"
                :width="15"
                :height="15"
                viewBox="0 0 15 15"
                class="me-2"
              />Voltar
            </RouterLink>
          </div>
          <div class="d-flex flex-grow-1 h-100 pt-2 pt-md-5 pb-md-5">
            <div
              class="rounded border bg-white border-gray-200 w-100 shadow-sm"
            >
              <ChatConversation
                :consultation="consultation"
                :user="user"
                :isLoading="false"
                :conversations="conversations"
                @open-chat-note="isChatNoteOpen = true"
              />
            </div>
          </div>
        </div>
      </div>
      <Notes
        :customer="consultation?.customer"
        :noteOpen="isChatNoteOpen"
        @close-chat-note="isChatNoteOpen = false"
      />
    </div>
  </main>
</template>
