export const getDevices = async () => {
    try {

        const devices = await navigator.mediaDevices.enumerateDevices();
        const inputDevices = devices.filter(device => device.kind === 'audioinput');
        const outputDevices = devices.filter(device => device.kind === 'audiooutput');

        return [inputDevices, outputDevices];
    } catch (error) {
        console.error('Erro ao obter dispositivos de áudio:', error);
    }
};

export const updateDevice = async (call, type, selectedDevice) => {
    try {
        if (type === 'input') {
            await call.audio.setInputDevice(selectedDevice);
        }
        if (type === 'output') {
            await call.audio.speakerDevices.set(selectedDevice);
        }
    } catch (error) {
        console.error('Erro ao alternar dispositivo de entrada de áudio:', error);
    }
}