<script setup>
const props = defineProps({
  variant: {
    type: String,
    default: "requested",
  },
});

const variants = {
  requested: {
    textColor: "warning-700",
    bgColor: "warning-50",
  },
  "in-progress": {
    textColor: "gray-700",
    bgColor: "gray-100",
  },
  closed: {
    textColor: "gray-700",
    bgColor: "gray-300",
  },
  lost: {
    textColor: "error-700",
    bgColor: "error-50",
  },
};
</script>

<template>
  <span
    :class="`badge rounded-pill py-1 px-2 fs-9 fw-semibold lh-1 text-capitalize text-${
      variants[props.variant]?.textColor
    } bg-${variants[props.variant]?.bgColor}`"
  >
    <slot></slot>
  </span>
</template>
