import { ref } from "vue";
import { defineStore } from "pinia";

export const useChannelStatusStore = defineStore("channelsStatus", () => {
  /**
   * @typedef {object} Channel
   * @property {string} status The status of the channel is either "connected" or "disconnected".
   * @property {object} members Information about the members connected on the channel.
   * @property {number} members.count How many users are connected on the channel.
   */

  /**
   * Description of Channels ref. "Channel" means the properties defined in the @typedef Channel
   * The data structure is:
   * {
   *   'presence-consultation-1': Channel,
   *   'presence-consultation-2': Channel,
   * }
   */
  const channels = ref({});

  /**
   * Get a channel by name.
   *
   * @param {string} channelName A valid Pusher's channel name.
   * @returns {( Channel | null )}
   */
  const getChannel = (channelName) => {
    if (channelName) {
      if (!channels.value.hasOwnProperty(channelName)) {
        return null;
      }

      return channels.value[channelName];
    }

    return null;
  };

  /**
   * Fetch data from a Pusher channel based on it's channel name.
   * If channel instance is found on Pusher, set default data on store state.
   *
   * @param {string} channelName A valid Pusher's channel name.
   */
  const fetchChannel = (channelName) => {
    const channel = window.webSocketAdapter.channel(channelName);

    if (channel) {
      channels.value[channelName] = {
        members: {
          count: channel.members.count,
        },
        status: "connected",
      };
    }
  };

  /**
   * Get channel from state and update its properties.
   *
   * @param {string} channelName A valid Pusher's channel name.
   * @param {object} data An object with the keys and values to be updated.
   */
  const updateChannel = (channelName, data) => {
    const channel = getChannel(channelName);

    if (channel) {
      Object.assign(channel, data);
    }
  };

  return { channels, getChannel, updateChannel, fetchChannel };
});
