import { BaseRepository } from "@astrocentro-webapp/sellers/modules/commons/repositories/BaseRepository";
import { HttpClient } from "@astrocentro-webapp/sellers/modules/commons/clients/HttpClient";

export class UserRepository extends BaseRepository {
  constructor() {
    super();

    this.resource = "users";
  }

  /**
   * Get the logged in user
   *
   * @returns {JSON}
   */
  async getLoggedInUser() {
    const httpClient = HttpClient.create();
    const { data } = await httpClient.get(
      "v1/current-user?relationships[]=seller.tphWpAccountSeller"
    );

    return data;
  }
}
