import { ConsultationRepository } from "@astrocentro-webapp/sellers/modules/commons/repositories/ConsultationRepository";
import { useConsultationsStore } from "@astrocentro-webapp/sellers/stores/consultations";

export class RefuseConsultationService {
  consultationsStore;

  constructor() {
    this.consultationsStore = useConsultationsStore();
  }

  /**
   * Refuse a chat consultation request
   *
   * @param {Number} consultationId
   * @returns {Object}
   */
  async perform(consultationId) {
    const status = "finished";
    const reason = "refused-by-seller";
    const consultationRepository = new ConsultationRepository();

    this.consultationsStore.$patch(async (state) => {
      let activeCalls = await state.consultations["Chamadas Ativas"];
      let inactiveCalls = await state.consultations["Chamadas Inativas"];

      activeCalls = activeCalls.filter((item) => {
        if (item.id == consultationId) {
          item.last_status = status;
          inactiveCalls.push(item);
        }

        return item.id != consultationId;
      });

      state.consultations = {
        "Chamadas Ativas": activeCalls,
        "Chamadas Inativas": inactiveCalls,
      };
    });

    return await consultationRepository.update(consultationId, {
      last_status: status,
      last_status_reason: reason,
    });
  }
}
