import { createRouter, createWebHistory } from "vue-router";

import LoginView from "@astrocentro-webapp/sellers/modules/login/views/LoginView.vue";
import LogoutView from "@astrocentro-webapp/sellers/modules/login/views/LogoutView.vue";
import ConversationsView from "@astrocentro-webapp/sellers/modules/chat/views/ConversationsView.vue";
import ChatView from "@astrocentro-webapp/sellers/modules/chat/views/ChatView.vue";
import ConsultationHistoryView from "@astrocentro-webapp/sellers/modules/chat/views/ConsultationHistoryView.vue";
import ConsultationHistoryChatView from "@astrocentro-webapp/sellers/modules/chat/views/ConsultationHistoryChatView.vue";
import CallConsultationView from "@astrocentro-webapp/sellers/modules/call/views/ConsultationView.vue";
import { AuthenticationPolicy } from "@astrocentro-webapp/sellers/modules/login/policies/AuthenticationPolicy";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "conversations",
      component: ConversationsView,
    },
    {
      path: "/conversations/:consultationId",
      name: "chat",
      component: ChatView,
    },
    {
      path: "/login",
      name: "login",
      component: LoginView,
    },
    {
      path: "/logout",
      name: "logout",
      component: LogoutView,
    },
    {
      path: "/consultations/history",
      name: "history",
      component: ConsultationHistoryView,
    },
    {
      path: "/consultations/call/:consultationId",
      name: "call-consultation-view",
      component: CallConsultationView,
    },
    {
      path: "/consultations/history/:consultationId",
      name: "historyChat",
      component: ConsultationHistoryChatView,
    },
  ],
});

router.beforeEach(async (to) => {
  const isLogin = to.name === "login";
  const isUserAuthenticated = await AuthenticationPolicy.isUserAuthenticated();

  if (!isUserAuthenticated && !isLogin) {
    return { name: "login" };
  }
});

export default router;
