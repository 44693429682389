<script setup>
import { RouterLink } from "vue-router";
import md5 from "md5";
import { computedEager } from "@vueuse/core";
const props = defineProps({
  consultation: {
    type: Object,
  },
});

const message = computedEager(() => {
  switch (props.consultation?.last_status_reason) {
    case "max-duration":
      return "Você atingiu o tempo limite da sua consulta. Ficou com alguma dúvida, entre em contato com o SAC";  
    case "finished-due-inactivity":
      return "Sua consulta foi encerrada por falta de interação. Qualquer dúvida, entre em contato com o SAC";
  }
  return "Sua consulta foi encerrada";
});
</script>

<template>
  <div>
    <h3 class="mb-1">{{ message }}</h3>
    <RouterLink
      :to="`/consultations/${props.consultation?.chat_room_id ? 'chat' : 'call'}/${md5(String(props.consultation?.id))}/summary`"
      class="text-primary fs-8"
    >
      Clique aqui para ter acesso ao resumo do seu atendimento.
    </RouterLink>
  </div>
</template>
