
import { useCallStore } from "@astrocentro-webapp/sellers/stores/call";
import { storeToRefs } from "pinia";
export class FinishCallConsultationService {

    perform() {
        const store = useCallStore();
        const { call } = storeToRefs(store);

        if (!call.value) {
          return;
        }

        call.value.disconnect();
        call.value = null;
    }
}