import { ConversationRepository } from "@astrocentro-webapp/sellers/modules/commons/repositories/ConversationRepository";
import { ConversationMessageRepository } from "@astrocentro-webapp/sellers/modules/commons/repositories/ConversationMessageRepository";
import { CreateConversationService } from "@astrocentro-webapp/sellers/modules/chat/services/CreateConversationService";
import { DateHelper } from "@astrocentro-webapp/sellers/modules/commons/helpers/DateHelper";
import { useActiveConsultationStore } from "@astrocentro-webapp/sellers/stores/activeConsultation";

export const storeMessageInLocalDatabaseListener = async ({
  chatRoomId,
  consultationId,
  ...message
}) => {
  const dateHelper = new DateHelper();
  const conversationRepository = new ConversationRepository();
  const conversationMessageRepository = new ConversationMessageRepository();
  const createConversationService = new CreateConversationService();
  const activeConsultationStore = useActiveConsultationStore();
  const currentDate = dateHelper.format(null, "yyyy-MM-dd");

  try {
    const localConversation = await conversationRepository.getFirst({
      date: currentDate,
      chatRoomId,
    });

    if (
      !localConversation &&
      activeConsultationStore.activeConsultation?.chat_room_id
    ) {
      await createConversationService.perform(
        activeConsultationStore.activeConsultation
      );
    }

    const [isStored] = await conversationMessageRepository.getFirst(
      chatRoomId,
      message.id
    );

    if (!isStored) {
      return conversationMessageRepository.add(chatRoomId, {
        ...message,
        hasError: !window.navigator.onLine,
      });
    }
  } catch (error) {
    console.log("[ storeMessageInLocalDatabaseListener - seller ]", error);
  }
};
