import { useConsultationsStore } from "@astrocentro-webapp/sellers/stores/consultations";

export const updateConsultationsStoreListener = async (
  consultation,
  customer,
  seller
) => {
  const store = useConsultationsStore();
  const consultationState = { ...consultation, customer, seller };

  store.$patch(async (state) => {
    const activeCalls = await state.consultations["Chamadas Ativas"];
    const inactiveCalls = await state.consultations["Chamadas Inativas"];
    const isConsultationStored = activeCalls.find((storedConsultation) => storedConsultation.id === consultation.id);

    if (!isConsultationStored) {
      state.consultations = {
        "Chamadas Ativas": [...activeCalls, consultationState],
        "Chamadas Inativas": inactiveCalls,
      };
    }
  });
};
