<script setup>
import { VIcon } from "@astrocentro-webapp/ui-components/modules/commons/components/VIcon";
import { VDefaultAvatar } from "@astrocentro-webapp/ui-components/modules/commons/components/VDefaultAvatar";

const props = defineProps({
  status: {
    type: String,
    default: "unavailable",
    validator(value) {
      return ["unavailable", "available", "busy"].includes(value);
    },
  },
  src: {
    type: String,
  },
  size: {
    type: String,
    default: "sm",
  },
  name: {
    type: String,
    default: "A",
  },
  alignment: {
    type: String,
    default: "left",
  },
});

const variants = {
  unavailable: {
    class: "bg-gray-300",
    name: "offline",
  },
  available: {
    class: "bg-green",
    name: "online",
  },
  busy: {
    class: "bg-orange",
    name: "ocupado",
  },
};
</script>

<template>
  <div>
    <div class="position-relative d-inline-block" v-if="props.src">
      <img
        :class="`rounded-circle avatar-${props.size} p-0`"
        :src="props.src"
      />
      <span
        v-if="props.status"
        :class="`avatar-badge position-absolute translate-middle ${
          variants[props.status].class
        } align-${props.alignment} border border-2 border-white rounded-circle`"
      ></span>
    </div>
    <div v-else>
      <VDefaultAvatar :name="props.name" :size="props.size" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@astrocentro-webapp/ui-components/assets/stylesheets/variables.scss";

.avatar-badge {
  top: 80%;
  left: 5%;
  padding: $base-size * 0.75;

  &.align-right {
    left: 95%;
  }
}
</style>
