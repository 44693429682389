import { CreateConversationService } from "@astrocentro-webapp/sellers/modules/chat/services/CreateConversationService";

export const storeConversationInLocalDatabaseListener = async ({
  consultation,
  customer,
  seller,
}) => {
  const createConversationService = new CreateConversationService();

  return createConversationService.perform({ ...consultation, customer, seller });
};
