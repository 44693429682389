<script setup>
import { ref } from "vue";

import { VDefaultAvatar } from "@astrocentro-webapp/ui-components/modules/commons/components/VDefaultAvatar";
import { ConfirmRefuseConsultationModal } from "@astrocentro-webapp/sellers/modules/commons/components/ConfirmRefuseConsultationModal";
import { AcceptCallConsultationService } from "@astrocentro-webapp/sellers/modules/call/services/AcceptCallConsultationService";

const props = defineProps({
  consultation: {
    type: Object,
    default() {
      return {
        id: null,
        customer: {
          name: null,
        },
      };
    },
  },
});

const openConfirmationModal = ref(false);
const isLoading = ref(false);
const emit = defineEmits(["show-pre-consultation-feedback"]);
const acceptChatConsultationService = new AcceptCallConsultationService();

const acceptConsultation = async (consultationId) => {
  isLoading.value = true;
  emit("show-pre-consultation-feedback", { value: true });
  await acceptChatConsultationService.perform(consultationId);
};
</script>

<template>
  <div>
    <div class="text-center">
      <VDefaultAvatar size="lg" class="fs-1 fw-bold mb-5" />
      <h3 class="fs-4 text-gray-900">
        {{ props.consultation?.customer?.name }} está te aguardando
      </h3>
      <p
        @click="$emit('open-chat-note')"
        class="fs-8 text-primary"
        role="button"
      >
        Abrir ficha do cliente
      </p>
      <p class="fs-8 text-gray-700 extra-margin">
        Você deseja realizar uma consulta com esse cliente?
      </p>
      <div class="d-grid">
        <button
          class="btn btn-primary mb-2"
          @click="acceptConsultation(props.consultation?.id)"
          :disabled="isLoading"
          type="button"
        >
          Aceitar
        </button>
        <button
          class="btn btn-outline-gray-300 text-gray-700"
          @click="openConfirmationModal = true"
          :disabled="isLoading"
          type="button"
        >
          Recusar
        </button>
      </div>
    </div>
    <ConfirmRefuseConsultationModal
      :open="openConfirmationModal"
      :consultation="consultation"
      @modal-closed="openConfirmationModal = false"
    />
  </div>
</template>

<style lang="scss" scoped>
@import "@astrocentro-webapp/ui-components/assets/stylesheets/variables.scss";
.extra-margin {
  margin-bottom: $base-size * 8;
}
</style>
