<script setup>
import { Modal } from "bootstrap";
import { VLogo } from "@astrocentro-webapp/ui-components/modules/commons/components/VLogo";
import { onMounted, onUnmounted, ref, watch } from "vue";

const emit = defineEmits(["modal-closed"]);
const modal = ref(null);
const props = defineProps({
  open: {
    type: Boolean,
  },
  closeButton: {
    type: Boolean,
    default: true,
  },
  backdrop: {
    type: [Boolean, String],
    default: true,
  },
  keyboard: {
    type: Boolean,
    default: true,
  },
  variant: {
    type: String,
    default: "default",
    validator(value) {
      return ["default", "full"].includes(value);
    },
  },
  logo: {
    type: Boolean,
  },
});

const variants = {
  default: "",
  full: "modal-fullscreen",
};

let bsModal = null;

const onModalClosed = () => emit("modal-closed");

onMounted(() => {
  if (modal.value) {
    bsModal = new Modal(modal.value, {
      backdrop: props.backdrop,
      keyboard: props.keyboard,
    });
    modal.value.addEventListener("hidden.bs.modal", onModalClosed);
    props.open && bsModal.show();
  }
});

onUnmounted(() => {
  bsModal.dispose();
});

watch(
  () => props.open,
  (open) => {
    open && bsModal.show();
    open || bsModal.hide();
  }
);
</script>

<template>
  <div class="modal fade p-0 m-0" ref="modal" tabindex="-1" aria-hidden="true">
    <div
      :class="`modal-dialog modal-dialog-centered ${variants[props.variant]}`"
    >
      <div class="modal-content p-5 border-0">
        <div class="mb-2 text-end">
          <button
            v-if="props.closeButton"
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Fechar"
            @click="onModalClosed"
          ></button>
        </div>
        <div v-if="props.logo" class="mb-5 text-center">
          <VLogo variant="md" />
        </div>
        <main>
          <slot></slot>
        </main>
      </div>
    </div>
  </div>
</template>
