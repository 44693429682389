<script setup>
import { reactive, ref, createApp, h, watch, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { storeToRefs } from "pinia";
import { useActiveConsultationStore } from "@astrocentro-webapp/sellers/stores/activeConsultation";
import { UpdateConsultationStoreService } from "@astrocentro-webapp/sellers/modules/chat/services/UpdateConsultationStoreService";
import { ClearFinishedConsultationsHistoryService } from "@astrocentro-webapp/sellers/modules/chat/services/ClearFinishedConsultationsHistoryService";
import { AlertLostConsultation } from "@astrocentro-webapp/sellers/modules/chat/components/AlertLostConsultation";
import { ListConversationsTabsCard } from "@astrocentro-webapp/sellers/modules/chat/components/ListConversationsTabsCard";
import { WaitingNotice } from "@astrocentro-webapp/sellers/modules/chat/components/WaitingNotice";
import { VButton } from "@astrocentro-webapp/ui/modules/commons/components/VButton";
import { isFinishedPhoneConsultation } from "@astrocentro-webapp/sellers/modules/commons/utils/isFinishedPhoneConsultation";
import { useConsultationsQueueStore } from "@astrocentro-webapp/sellers/stores/consultationsQueue";
import { VIcon } from "@astrocentro-webapp/ui-components/modules/commons/components/VIcon";
import { ModalAvailability } from "@astrocentro-webapp/sellers/modules/chat/components/ModalAvailability";

const router = useRouter();
const route = useRoute();
const activeConsultationStore = useActiveConsultationStore();
const updateConsultationStoreService = new UpdateConsultationStoreService();
const clearFinishedConsultationsHistoryService =
  new ClearFinishedConsultationsHistoryService();
const alertContainer = ref(null);
const alertLostConsultations = h(AlertLostConsultation, {
  onCheckLostConsultations: () => {
    handleChangeTab(1);
    state.alert.unmount();
  },
  onClose: () => state.alert.unmount(),
  class: "m-3",
});

const consultationsQueueStore = useConsultationsQueueStore();
const { consultationsQueue } = storeToRefs(consultationsQueueStore);

onMounted(() => {
  consultationsQueue.value.map((item) => {
    item.last_status_reason = "queue-waiting";
  });
});

const props = defineProps({
  tabs: {
    type: Object,
    default: [],
  },
});

const state = reactive({
  activeTab: route.query.tab == "inactive" ? 1 : 0,
  alert: null,
});

const getTabName = (name) =>
  name === "Chamadas Inativas" ? "Histórico de chamadas" : name;

const handleChangeTab = (index) => {
  state.activeTab = index;
  if (index == 0) router.push("/");
};

const handleActiveConversation = async (item, consultationType) => {
  const tab = state.activeTab === 1 ? "inactive" : "active";

  if (
    isFinishedPhoneConsultation(
      state.activeTab,
      consultationType,
      item.last_status
    )
  )
    return;

  activeConsultationStore.$patch(async (state) => {
    state.activeConsultation = item;
  });

  if (item.hasNotification) {
    updateConsultationStoreService.perform(item.id, {
      hasNotification: false,
    });
  }

  const route =
    consultationType === "phone-consultation"
      ? `/consultations/call/${item.id}?tab=${tab}`
      : `/conversations/${item.id}?tab=${tab}`;

  router.push(route);
};

const openAlert = () => {
  state.alert = createApp(alertLostConsultations);
  state.alert.mount(alertContainer.value);
};

watch(
  () => props.tabs,
  (conversations) => {
    const lostConversations = conversations["Chamadas Inativas"].filter(
      (conversation) =>
        conversation.last_status_reason == "timeout" ||
        conversation.last_status_reason == "given-up-by-customer"
    ).length;
    if (lostConversations > sessionStorage.getItem("lostConversations")) {
      openAlert();
      sessionStorage.setItem("lostConversations", lostConversations);
    } else if (lostConversations == 0) {
      sessionStorage.setItem("lostConversations", 0);
    }
  }
);

const handleClearHistory = () => {
  clearFinishedConsultationsHistoryService.perform();
  sessionStorage.setItem("lostConversations", 0);
};
</script>

<template>
  <div class="h-100 d-flex flex-column">
    <ul class="nav px-lg-3 border-bottom border-gray-100">
      <li
        class="nav-item flex-grow-1 text-center"
        v-for="(value, name, index) of props.tabs"
      >
        <a
          :class="`nav-link fw-bold fs-8 pt-3 pb-2 px-2 text-truncate ${
            state.activeTab == index ? 'text-primary active' : 'text-gray-500'
          }`"
          aria-current="page"
          href="#"
          @click="handleChangeTab(index)"
          >{{ getTabName(name) }}</a
        >
      </li>
    </ul>
    <div ref="alertContainer" v-if="state.activeTab == 0"></div>
    <div class="p-3 mb-0" v-if="state.activeTab == 1">
      <VButton
        class="btn-lg btn-outline-gray-300 text-gray-700 fw-bold w-100 fs-8"
        @click="handleClearHistory"
        >Limpar todo o seu Histórico</VButton
      >
    </div>
    <div class="h-100 overflow-auto">
      <ul class="list-group">
        <li
          v-for="(item, index) of props.tabs[
            Object.keys(props.tabs)[state.activeTab]
          ]"
          :class="`list-group-item p-0 border-0 position-relative ${
            route.params.consultationId == item.id
              ? 'bg-gray-100 active text-gray-900'
              : ''
          }`"
        >
          <ListConversationsTabsCard
            :activeTab="state.activeTab"
            :item="item"
            :hasNewMessage="item.hasNotification"
            @handleConversationClick="handleActiveConversation"
          />
        </li>
      </ul>
      <ul v-if="consultationsQueue.length" class="list-group">
        <li v-if="state.activeTab == 0">
          <div class="p-3">
            <div
              class="d-flex justify-content-start align-items-center p-1 bg-gray-100 rounded-2"
            >
              <VIcon
                :width="16"
                :height="16"
                icon="star-list-queue-consultation"
              />
              <span class="ps-2">Atendimento por voz no seu aguardo</span>
            </div>
          </div>
        </li>
        <li
          v-if="state.activeTab == 0"
          v-for="(item, index) of consultationsQueue"
          class="list-group-item border-0 p-0 position-relative"
        >
          <div class="px-3" role="button">
            <ListConversationsTabsCard
              :item="item"
              :hasNewMessage="item.hasNotification"
            />
          </div>
        </li>
      </ul>
    </div>

    <div
      class="h-100"
      v-if="
        props.tabs[Object.keys(props.tabs)[0]]?.length == 0 &&
        state.activeTab == 0
      "
    >
      <div class="d-lg-none px-3 h-100">
        <div class="d-flex justify-content-center align-items-center h-100">
          <WaitingNotice />
        </div>
      </div>
    </div>
  </div>
  <ModalAvailability />
</template>

<style lang="scss" scoped>
@import "@astrocentro-webapp/ui-components/assets/stylesheets/variables.scss";

.list-group {
  flex: 1 1 0;

  .list-group-item {
    & + .list-group-item.active {
      margin-top: 0;
    }
  }
}
.nav-link {
  &.active {
    border-bottom: 2px solid $primary;
  }
}
</style>
