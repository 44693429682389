import { useOnline } from "@vueuse/core";

import { useChannelStatusStore } from "@astrocentro-webapp/commons/modules/chat/stores/channelStatusStore";

export class SetChatChannelStatusService {
  #channelName;
  #channelStatusStore;

  /**
   * Constructor
   * @param {string} channelName A valid Pusher's chat channel name like "presence-consultation-1".
   */
  constructor(channelName) {
    this.#channelName = channelName;
    this.#channelStatusStore = useChannelStatusStore();
  }

  /**
   * Defines the status of a chat channel.
   * First verifies if user has internet connection,
   * then verifies if a minimum amount of members are connected to the channel.
   *
   * @returns {string} A string representing the status which is either "connected" or "disconnected".
   */
  #getStatus() {
    const isOnline = useOnline();
    const channelMembersCount = this.#channelStatusStore.getChannel(
      this.#channelName
    )?.members?.count;

    if (!isOnline.value) {
      return "disconnected";
    }

    if (!channelMembersCount) {
      return "disconnected";
    }
    if (channelMembersCount < 2) {
      return "disconnected";
    }

    return "connected";
  }

  /**
   * Set the status of a channel by fetching latest channel data from store
   */
  perform() {
    this.#channelStatusStore.fetchChannel(this.#channelName);
    const status = this.#getStatus();
    this.#channelStatusStore.updateChannel(this.#channelName, { status });
  }
}
