<script setup>
import { ref, inject, computed } from "vue";
import { useOnline } from "@vueuse/core";

import { VDefaultAvatar } from "@astrocentro-webapp/ui-components/modules/commons/components/VDefaultAvatar";
import { SellerAvatar } from "@astrocentro-webapp/ui-components/modules/sellers/components/SellerAvatar";
import { DateHelper } from "@astrocentro-webapp/commons/modules/commons/helpers/DateHelper";
import { useChannelStatusStore } from "@astrocentro-webapp/commons/modules/chat/stores/channelStatusStore";

const props = defineProps({
  recipientUser: {
    type: Object,
    default() {
      return { user_id: 0, name: "", thumbnail: "" };
    },
  },
  data: {
    type: Object,
    default() {
      return {
        id: 0,
        sender: {
          id: 0,
          username: "",
          type: "",
        },
        message: {
          template: "text",
          payload: {
            message: "",
          },
        },
        consultation: {
          last_status: "",
          external_id: "",
          chat_room_id: 0,
        },
        time: new Date().toISOString(),
        isRead: false,
        hasError: false,
      };
    },
  },
});

const isOnline = useOnline();
const isLoading = ref(false);
const chatRetryMessageSendingService = inject(
  "chat-retry-message-sending-service"
);
const dateHelper = new DateHelper();
const store = useChannelStatusStore();
const status = computed(
  () =>
    store.getChannel(`presence-consultation-${props.data.consultation.id}`)
      ?.status
);
const formattedDate = computed(() => {
  return dateHelper
    .intlFormat(props.data.time, {
      weekday: "short",
      hour: "numeric",
      minute: "numeric",
    })
    .replace(",", "");
});
const isMe = computed(
  () => props.data.sender.id !== props.recipientUser.user_id
);
const formattedUsername = computed(() =>
  isMe.value ? "Você" : props.data.sender.username
);
const showRetryButton = computed(() => {
  if (!isOnline.value) {
    return false;
  }

  return (
    props.data.consultation.last_status !== "finished" &&
    status.value === "connected"
  );
});

const verifyIfMessageWasSent = () => {
  const maxRetries = 60;
  let loop = 1;

  const interval = setInterval(() => {
    if ((!props.data.hasError && props.data.isRead) || loop > maxRetries) {
      clearInterval(interval);
      isLoading.value = false;

      return;
    }

    if (!props.data.hasError && props.data.isRead) {
      isLoading.value = false;

      return;
    }

    loop++;
  }, 1000);
};

const retrySendMessageWithInterval = (message) => {
  const maxRetries = 6;
  let loop = 1;

  const interval = setInterval(() => {
    if ((!props.data.hasError && props.data.isRead) || loop > maxRetries) {
      clearInterval(interval);

      return;
    }

    chatRetryMessageSendingService.perform(
      message,
      props.data.sender,
      props.data.consultation
    );

    loop++;
  }, 1000 * 10);
};

const handleClickRetry = async () => {
  isLoading.value = true;
  const message = {
    ...props.data.message,
    id: props.data.id,
    time: props.data.time,
  };
  chatRetryMessageSendingService.perform(
    message,
    props.data.sender,
    props.data.consultation
  );
  verifyIfMessageWasSent();
  retrySendMessageWithInterval(message);
};
</script>

<template>
  <div
    :class="`d-flex gap-2 chat-message ${isMe ? 'chat-message-sender' : ''} `"
  >
    <div v-if="!isMe">
      <SellerAvatar
        status="available"
        :src="props.recipientUser.thumbnail"
        size="xs"
        alignment="right"
        v-if="props.recipientUser.thumbnail"
      />
      <VDefaultAvatar
        class="flex-shrink-0"
        :name="props.recipientUser.name"
        v-else
      />
    </div>
    <div class="w-100">
      <div
        class="d-flex justify-content-between align-items-center gap-2 mb-2 chat-message-top"
      >
        <div class="text-gray-700 fw-semibold fs-8 text-truncate">
          {{ formattedUsername }}
        </div>
        <small class="text-gray-600">{{ formattedDate }}</small>
      </div>
      <p
        v-if="props.data.message.template !== 'html'"
        :disabled="isLoading"
        :class="`text-gray-900 p-2 chat-message-text text-break data-hj-suppress ${
          props.data.hasError ? 'mb-2' : ''
        }`"
      >
        {{ props.data.message.payload.message }}
      </p>
      <p
        v-else
        :disabled="isLoading"
        :class="`text-gray-900 p-2 chat-message-text text-break data-hj-suppress ${
          props.data.hasError ? 'mb-2' : ''
        }`"
        v-html="props.data.message.payload.message"
      ></p>
      <div
        v-if="
          props.data.hasError &&
          !isLoading &&
          props.data.sender.id !== props.recipientUser.user_id
        "
        class="d-flex justify-content-between gap-2 mb-3"
      >
        <small
          :class="[
            'd-inline-block',
            'text-primary-500',
            { invisible: isLoading },
          ]"
          >Mensagem não enviada.</small
        >
        <a
          v-if="showRetryButton"
          href="#"
          :class="['link-primary-500', 'fs-8', { invisible: isLoading }]"
          @click.prevent="handleClickRetry"
          >Reenviar</a
        >
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@astrocentro-webapp/ui-components/assets/stylesheets/variables.scss";
@import "@astrocentro-webapp/ui-components/assets/stylesheets/vendor.scss";

.chat-message {
  max-width: 80%;
  align-self: flex-start;

  &-top {
    min-width: $base-size * 12;
  }

  &-text {
    border-radius: 0 $base-size $base-size $base-size;
    min-width: $base-size * 12;
    min-height: $base-size * 5;
    background-color: $gray-100;
  }

  &-sender {
    align-self: flex-end;

    .chat-message-text {
      background-color: $blue-20;

      &[disabled="true"] {
        opacity: 0.5;
        cursor: wait;
      }
    }
  }
}
@include media-breakpoint-up(md) {
  .chat-message {
    max-width: 55%;
  }
}
</style>
