import { ConversationMessageRepository } from "@astrocentro-webapp/sellers/modules/commons/repositories/ConversationMessageRepository";
import { updateMessageHasErrorListener } from "@astrocentro-webapp/sellers/modules/chat/listeners/updateMessageHasErrorListener";
import { ConsultationChannelEvents } from "@astrocentro-webapp/sellers/modules/chat/events/ConsultationChannelEvents";
import { FormatMessagePayloadHelper } from "@astrocentro-webapp/commons/modules/chat/helpers/FormatMessagePayloadHelper";

export class RetryMessageSendingService {
  #conversationMessageRepository;

  constructor() {
    this.#conversationMessageRepository = new ConversationMessageRepository();
  }

  async perform(message, sender, consultation) {
    const consultationChannelEvents = new ConsultationChannelEvents(
      consultation.id
    );
    const messagePayload = FormatMessagePayloadHelper.perform(
      sender,
      consultation,
      message,
    );
    await this.#conversationMessageRepository.update(
      consultation.chat_room_id,
      message.id,
      { hasError: false }
    );
    consultationChannelEvents.trigger(
      "client-chat-message-received",
      messagePayload
    );
    updateMessageHasErrorListener(messagePayload);
  }
}
