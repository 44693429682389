export class AuthenticationPolicy {
  /**
   * Check if an user is authenticated
   *
   * @returns {boolean}
   */
  static async isUserAuthenticated() {
    return localStorage.accessToken ? true : false;
  }
}
