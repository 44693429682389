import { ConsultationRepository } from "@astrocentro-webapp/sellers/modules/commons/repositories/ConsultationRepository";
import { DateHelper } from "@astrocentro-webapp/commons/modules/commons/helpers/DateHelper";
import { LoginService } from "@astrocentro-webapp/sellers/modules/login/services/LoginService.js";

export class GetConsultationsService {
  #store;
  #consultationRepository;
  #loginService;

  constructor(store) {
    this.#store = store;
    this.#consultationRepository = new ConsultationRepository();
    this.#loginService = new LoginService();
  }

  /**
   * Get all current consultations for the logged seller.
   *
   * @returns {Consultation[]}
   */
  async perform() {
    const data = await new Promise((resolve, reject) => {
      this.#store.$patch(async (state) => {
        try {
          const loggedInUser = await this.#loginService.getLoggedInUser();

          if (!loggedInUser || !loggedInUser?.seller) {
            state.consultations = {
              "Chamadas Ativas": [],
              "Chamadas Inativas": [],
            };

            resolve(null);
          }

          const dateHelper = new DateHelper();
          const currentDate = dateHelper.format(null, "yyyy-MM-dd");
          // TODO: Rename storage key to respect standards
          const clearHistoryDate = localStorage.getItem("clear-history-date");
          let consultations = await this.#consultationRepository.get(
            `?filters[]=created_at,>=,${currentDate}&filters[]=seller_id,=,${loggedInUser.seller.id}&relationships[]=customer&relationships[]=seller&orFilters[]=chat_room_id,>,0&orFilters[]=call_device_id,!=,null`
          );

          consultations = consultations.map((consultation) => {
            return { ...consultation, hasNotification: false };
          });

          const active = consultations.filter(
            (consultation) =>
              consultation.last_status == "in-progress" ||
              consultation.last_status == "requested"
          );

          const inactive = consultations.filter((consultation) => {
            if (consultation.last_status == "finished") {
              if (!clearHistoryDate) {
                return true;
              }

              if (
                dateHelper.isAfter(consultation.created_at, clearHistoryDate)
              ) {
                return true;
              }
            }
          });

          state.consultations = {
            "Chamadas Ativas": active,
            "Chamadas Inativas": inactive,
          };

          resolve(consultations);
        } catch (error) {
          reject(error);
        }
      });
    });

    return data;
  }
}
