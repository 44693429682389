import { ConsultationChannelEvents } from "@astrocentro-webapp/sellers/modules/chat/events/ConsultationChannelEvents";
import { ConsultationRepository } from "@astrocentro-webapp/sellers/modules/commons/repositories/ConsultationRepository";
import { CreateConversationService } from "@astrocentro-webapp/sellers/modules/chat/services/CreateConversationService";
import { DateHelper } from "@astrocentro-webapp/sellers/modules/commons/helpers/DateHelper";
import { UpdateConsultationStoreService } from "@astrocentro-webapp/sellers/modules/chat/services/UpdateConsultationStoreService";
import { ConversationRepository } from "@astrocentro-webapp/sellers/modules/commons/repositories/ConversationRepository";
import { useConsultationsStore } from "@astrocentro-webapp/sellers/stores/consultations";

export class AcceptChatConsultationService {
  createConversationService;
  dateHelper;
  updateConsultationStoreService;
  conversationRepository;
  consultationsStore;

  constructor() {
    this.createConversationService = new CreateConversationService();
    this.dateHelper = new DateHelper();
    this.updateConsultationStoreService = new UpdateConsultationStoreService();
    this.conversationRepository = new ConversationRepository();
    this.consultationsStore = useConsultationsStore();
  }

  /**
   * Accept a chat consultation request
   *
   * @param {Number} consultationId
   * @returns {Object}
   */
  async perform(consultationId) {
    const status = "in-progress";
    const status_reason = "accepted-by-seller";
    const startDate = this.dateHelper.format(new Date(), "yyyy-MM-dd HH:mm:ss");
    const currentDate = this.dateHelper.format(null, "yyyy-MM-dd");
    const consultationChannelEvents = new ConsultationChannelEvents(
      consultationId
    );
    const consultationRepository = new ConsultationRepository();
    const activeConsultations = await this.consultationsStore.consultations["Chamadas Ativas"];
    const activeConsultation = activeConsultations.find(
      (activeConsultation) => activeConsultation.id === consultationId
    );

    consultationChannelEvents.subscribe();

    const localConversation = await this.conversationRepository.getFirst({
      date: currentDate,
      chatRoomId: activeConsultation.chat_room_id,  
    });

    if (!localConversation) {
      await this.createConversationService.perform(activeConsultation);
    }

    await this.updateConsultationStoreService.perform(consultationId, {
      last_status: status,
      last_status_reason: status_reason,
      start_date: startDate,
    });

    return consultationRepository.update(consultationId, {
      last_status: status,
      last_status_reason: status_reason,
    });
  }
}
