<script setup>
import { reactive, onBeforeMount, watch } from "vue";

import { ChatConfirmationModal } from "@astrocentro-webapp/sellers/modules/chat/components/ChatConfirmationModal";
import { VDefaultAvatar } from "@astrocentro-webapp/ui-components/modules/commons/components/VDefaultAvatar";
import { VTextArea } from "@astrocentro-webapp/ui-components/modules/commons/components/VTextArea";
import { VButton } from "@astrocentro-webapp/ui/modules/commons/components/VButton";
import { NoteRepository } from "@astrocentro-webapp/sellers/modules/commons/repositories/NoteRepository";
import { UserRepository } from "@astrocentro-webapp/commons/modules/commons/repositories/UserRepository";
import { DateHelper } from "@astrocentro-webapp/sellers/modules/commons/helpers/DateHelper";

import Trash from "@astrocentro-webapp/ui-components/assets/icons/trash.svg?component";
import Send from "@astrocentro-webapp/ui-components/assets/icons/send.svg?component";
import ArrowLeft from "@astrocentro-webapp/ui-components/assets/icons/arrow-left.svg?component";

const props = defineProps({
  customer: {
    type: Object,
    default() {
      return {
        name: "",
        birthdate: null,
        user_id: 0,
      };
    },
  },
  noteOpen: {
    type: Boolean,
  },
});

const state = reactive({
  openModal: false,
  notes: [],
  note: "",
  noteId: 0,
  isLoading: false,
});
const noteRepository = new NoteRepository();
const dateHelper = new DateHelper();
const userRepository = new UserRepository();
const user = await userRepository.getLoggedInUser();

const confirmDelete = (id) => {
  state.openModal = true;
  state.noteId = id;
};

const deleteNote = async () => {
  const isNoteDeleted = await noteRepository.delete(state.noteId);

  if (!isNoteDeleted) {
    return;
  }

  state.notes = state.notes.filter((note) => note.id !== state.noteId);
  state.openModal = false;
};

const saveNote = async () => {
  if (!user || !("id" in user) || !props.customer.user_id) {
    return;
  }

  state.isLoading = true;
  const data = await noteRepository.create({
    note: state.note,
    sender_user_id: user.id,
    recipient_user_id: props.customer.user_id,
  });
  state.notes.push(data);

  setTimeout(() => {
    let chatContentEl = document.querySelector(".note-content");

    if (chatContentEl) {
      chatContentEl.scrollTop = chatContentEl.scrollHeight;
    }
  }, 500);

  state.note = "";
  state.isLoading = false;
};

const getNotes = async () => {
  if (!user || !("id" in user) || !props.customer.user_id) {
    return;
  }

  const notes = await noteRepository.get(
    `?order[]=created_at,ASC&filters[]=sender_user_id,=,${user.id}&filters[]=recipient_user_id,=,${props.customer.user_id}`
  );
  state.notes = notes;
};

onBeforeMount(() => {
  getNotes();
});

watch(
  () => props.customer.user_id,
  async (customerUserId) => {
    if (customerUserId) {
      getNotes();
    }
  }
);
</script>

<template>
  <div>
    <div
      :class="`note h-100 position-absolute end-0 top-0 overflow-hidden note-open-${
        props.noteOpen
      } ${props.noteOpen ? 'visible' : 'invisible'}`"
    >
      <div
        class="note-container bg-gray-25 d-flex flex-column h-100 w-100 position-absolute top-0 border-start border-gray-100"
      >
        <div
          class="d-flex d-lg-none gap-3 border-bottom border-gray-100 p-3"
          @click="$emit('close-chat-note')"
        >
          <ArrowLeft width="20" height="20" />
          <p class="text-gray-900 fw-bold mb-0">Voltar para a consulta</p>
        </div>
        <div class="note-header p-3">
          <div class="d-flex justify-content-between">
            <div class="d-flex align-items-center">
              <VDefaultAvatar :name="props.customer.name" />
              <h6
                class="note-header-name fs-8 mb-0 ms-2 d-inline-block text-truncate"
              >
                {{ props.customer.name }}
                <small
                  v-if="props.customer.birthdate != null"
                  class="d-block text-gray-400 mb-1"
                  >{{
                    dateHelper.format(props.customer.birthdate, "dd/MM/yyyy")
                  }}</small
                >
              </h6>
            </div>
            <button
              type="button"
              class="btn-close text-reset"
              aria-label="Close"
              @click="$emit('close-chat-note')"
            ></button>
          </div>
        </div>
        <div class="note-content overflow-auto p-3">
          <div class="mb-3" v-for="{ note, id, created_at } of state.notes">
            <div class="p-3 bg-white border border-gray-300 rounded text-break">
              <p class="fs-8 mb-2">{{ note }}</p>
              <div class="text-end">
                <button class="btn btn-white p-0 lh-1">
                  <Trash
                    class="text-gray-600"
                    width="20"
                    height="20"
                    @click="confirmDelete(id)"
                  />
                </button>
              </div>
            </div>
            <small class="text-gray-400">{{
              dateHelper.format(created_at, "dd/MM/yyyy")
            }}</small>
          </div>
        </div>
        <div class="note-footer mt-4 p-3">
          <div class="text-end">
            <VTextArea
              placeholder="Digite sua mensagem aqui"
              v-model.trim="state.note"
            >
              <VButton
                class="btn-sm btn-primary"
                @click="saveNote"
                :disabled="state.note === '' || state.isLoading"
              >
                <Send width="20" height="20" />
              </VButton>
            </VTextArea>
          </div>
        </div>
      </div>
    </div>
    <ChatConfirmationModal
      title="Deseja mesmo excluir esta anotação?"
      id="delete-modal"
      aria-labelledby="deleteModalLabel"
      :open="state.openModal"
      @user-accepted="deleteNote"
      @modal-closed="state.openModal = false"
    />
  </div>
</template>

<style lang="scss" scoped>
@import "@astrocentro-webapp/ui-components/assets/stylesheets/variables.scss";
@import "@astrocentro-webapp/ui-components/assets/stylesheets/vendor.scss";

$chat-note-width: $base-size * 38;

.note {
  width: 100%;
  z-index: 100;

  &-open-true &-container {
    transition: 0.2s;
    right: 0;
  }
  &-container {
    transition: 0.2s;
    right: -$chat-note-width;
  }
  &-header {
    &-name {
      width: $base-size * 23.5;
    }
  }
  &-content {
    flex: 1 1 0;
  }
}

@include media-breakpoint-up(lg) {
  .note {
    max-width: $chat-note-width;
  }
}
</style>
