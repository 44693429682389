<script setup>
import { VModal } from "@astrocentro-webapp/ui-components/modules/commons/components/VModal";
import { VButton } from "@astrocentro-webapp/ui/modules/commons/components/VButton";
import { ref } from "vue";
import { RefuseConsultationService } from "@astrocentro-webapp/sellers/modules/commons/services/RefuseConsultationService.js";
import { useRouter } from "vue-router";

const open = ref(false);
const emit = defineEmits(["modal-closed"]);
const onModalClosed = () => emit("modal-closed");
const refuseConsultationService = new RefuseConsultationService();
const router = useRouter();

const props = defineProps({
  consultation: {
    type: Object,
    default() {
      return {
        id: null,
      };
    },
  },
});

const refuseConsultation = async (consultationId) => {
  await refuseConsultationService.perform(consultationId);
  emit("modal-closed");
  router.push("/");
};
</script>

<template>
  <VModal
    :open="open"
    id="close-modal"
    @modal-closed="onModalClosed"
    class="text-center"
    :logo="true"
  >
    <div class="p-md-5 pt-md-0 mx-md-5">
      <h1 class="mb-3">Você tem certeza que deseja recusar essa consulta?</h1>
      <p class="text-gray-500 mb-5">
        Você notificará seu cliente que no momento você não está disponível.
      </p>
      <div class="d-grid">
        <VButton
          class="btn-lg btn-primary"
          @click="refuseConsultation(props.consultation.id)"
        >
          Sim, quero recusar a consulta
        </VButton>
        <VButton
          class="btn-lg btn-outline-gray-300 mt-3 text-gray-700"
          @click="onModalClosed"
        >
          Voltar para o atendimento
        </VButton>
      </div>
    </div>
  </VModal>
</template>
