<script setup>

const props = defineProps({
  variant: {
    type: String,
    default: "md",
    validator(value) {
      return ["sm", "md"].includes(value);
    },
  },
});

const variants = {
  sm: {
    width: 150,
    height: 19,
  },
  md: {
    width: 258,
    height: 33,
  },
};
</script>

<template>
  <img
    src="https://cdn.astrocentro.com.br/webapp/logo-astrocentro.png"
    :width="variants[props.variant].width"
    :height="variants[props.variant].height"
  />
</template>
