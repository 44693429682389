import { useWebNotification } from "@vueuse/core";
import * as PusherPushNotifications from "@pusher/push-notifications-web";

const { isSupported } = useWebNotification();

export class PushNotificationsAdapter {
  constructor() {
    if (isSupported.value)
      this.beamsClient = new PusherPushNotifications.Client({
        instanceId: import.meta.env.VITE_PUSHER_INSTANCE_ID,
      });
  }
  async start(userId) {
    const beamsTokenProvider = await new PusherPushNotifications.TokenProvider({
      url: `${
        import.meta.env.VITE_ASTROCENTRO_API_URL
      }/v1/notifications/push/auth`,
      queryParams: {
        user_id: userId,
      },
      headers: {
        Authorization: `Bearer ${import.meta.env.VITE_ASTROCENTRO_API_TOKEN}`,
        Accept: "application/json",
      },
    });
    if (isSupported.value) {
      await this.beamsClient
        .getUserId()
        .then((currentUserId) => {
          if (currentUserId !== userId) {
            return this.beamsClient.stop();
          }
        })
        .catch(console.error);

      await this.beamsClient
        .start()
        .then(() =>
          this.beamsClient.setUserId(String(userId), beamsTokenProvider)
        )
        .catch(console.error);
    }
  }

  async stop() {
    if (isSupported.value) await this.beamsClient.stop().catch(console.error);
  }
}
