<script setup>
import { VBadge } from "@astrocentro-webapp/ui-components/modules/commons/components/VBadge";

const props = defineProps({
  lastStatusReason: {
    type: String,
    default: "",
  },
});

const label = {
  "queue-waiting": {
    status: "requested",
    label: "Aguardando",
  },
  "requested-by-customer": {
    status: "requested",
    label: "Pendente",
  },
  "accepted-by-seller": {
    status: "in-progress",
    label: "Em andamento",
  },
  "refused-by-seller": {
    status: "closed",
    label: "Encerrado",
  },
  "finished-by-seller": {
    status: "closed",
    label: "Encerrado",
  },
  "finished-by-customer": {
    status: "closed",
    label: "Encerrado",
  },
  "given-up-by-customer": {
    status: "lost",
    label: "Perdida",
  },
  "finished-due-inactivity": {
    status: "closed",
    label: "Encerrado",
  },
  "max-duration": {
    status: "closed",
    label: "Encerrado",
  },
  timeout: {
    status: "lost",
    label: "Perdida",
  },
};
</script>

<template>
  <VBadge :variant="label[props.lastStatusReason]?.status">{{
    label[props.lastStatusReason]?.label
  }}</VBadge>
</template>
