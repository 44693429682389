import { BaseRepository } from "@astrocentro-webapp/sellers/modules/commons/repositories/BaseRepository";
import { DatabaseAdapter } from "@astrocentro-webapp/sellers/modules/commons/adapters/DatabaseAdapter";

export class ConversationRepository extends BaseRepository {
  constructor() {
    super();

    const databaseAdapter = new DatabaseAdapter();
    this.resource = "conversations";
    this.databaseStorage = databaseAdapter.database;
  }

  get model() {
    return this.databaseStorage[this.resource];
  }

  /**
   * Get the related resources
   *
   * @returns {JSON}
   */
  async get(callback = null) {
    return callback ? this.model.toArray(callback) : this.model.toArray();
  }

  /**
   * Get the related resources
   *
   * @returns {JSON}
   */
  async getFirst(where) {
    // Explicity open database before query to avoid warnings
    this.databaseStorage.open();

    return this.model.where(where).first();
  }

  /**
   * Create a new resource
   *
   * @param {JSON} payload
   * @returns {JSON}
   */
  async create(payload) {
    this.databaseStorage.open();

    return this.model.add(payload);
  }

  /**
   * Update the specified resource
   *
   * @param {number} id
   * @param {JSON} payload
   * @returns {JSON}
   */
  async update(id, payload) {
    this.databaseStorage.open();

    return this.model.update(id, payload);
  }
}
