import { DateHelper } from "@astrocentro-webapp/sellers/modules/commons/helpers/DateHelper";

export class FormatMessagePayloadHelper {
  static perform(sender, consultation, message = null) {
    const dateHelper = new DateHelper();
    const time = dateHelper.format(null, "yyyy-MM-dd HH:mm:ss");

    return {
      id: message ? message.id : dateHelper.get(time).getTime(),
      isRead: false,
      hasError: false,
      sender,
      message: {
        template: message ? message.template : "text",
        payload: {
          message: message ? message.payload.message : "",
        },
      },
      time: message ? message.time : time,
      chatRoomId: consultation.chat_room_id,
      consultationId: consultation.id,
      consultationExternalId: consultation.external_id,
    };
  }
}
