import { SellerServiceRepository } from "@astrocentro-webapp/sellers/modules/commons/repositories/SellerServiceRepository";
import { GetSellerServicesService } from "@astrocentro-webapp/sellers/modules/sellers/services/GetSellerServicesService";
import { UserRepository } from "@astrocentro-webapp/sellers/modules/commons/repositories/UserRepository";

export class UpdateSellerServiceStatusService {
  #store;
  #sellerServiceRepository;
  #getSellerServicesService;
  #userRepository;

  constructor(store) {
    this.#store = store;
    this.#sellerServiceRepository = new SellerServiceRepository();
    this.#getSellerServicesService = new GetSellerServicesService();
    this.#userRepository = new UserRepository();
  }

  /**
   * @typedef {Object} Service
   * @property {number} id
   * @property {number} seller_id
   * @property {string} service
   * @property {number} price
   * @property {string} status
   * @property {boolean} connected
   * @property {Date} created_at
   * @property {number} updated_at
   */

  /**
   * Update seller service connection status.
   * @param availabilities
   * @returns {Promise<unknown>}
   */
  async perform(availabilities) {
    return new Promise((resolve, reject) => {
      this.#store.$patch(async (state) => {
        try {
          let services = await state.services;
          const isServicesEmpty = !Object.keys(services).length;

          if (isServicesEmpty) {
            state.services = (async () =>
              this.#getSellerServicesService.perform())();
          }

          services = await state.services;
          const sellerId = services[Object.keys(services)[0]].seller_id;

          if (!sellerId) {
            resolve(null);
          }

          let body = {
            services: [],
          };

          const user = await this.#userRepository.getLoggedInUser();
          const attendsInNewCall =
            user.seller && user.seller.tph_wp_account_seller
              ? user.seller.tph_wp_account_seller.attends_in_new_call
              : false;

          for (let key in availabilities) {
            if (key === "phone-consultation" && !attendsInNewCall) {
              continue;
            }

            if (availabilities.hasOwnProperty(key)) {
              body.services.push({
                id: services[key].id,
                connected: availabilities[key],
                status: availabilities[key] ? "available" : "unavailable",
              });
            }
          }

          const data = await this.#sellerServiceRepository.updateServices(
            sellerId,
            body
          );

          for (let key in data) {
            if (data.hasOwnProperty(key)) {
              services[data[key].service] = {
                ...services[data[key].service],
                ...data[key],
              };
            }
          }

          state.services = (async () => services)();

          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    });
  }
}
