<script setup>
import { ref, onMounted, provide, watch } from "vue";
import { onBeforeRouteUpdate, onBeforeRouteLeave } from "vue-router";
import { useRouteParams } from "@vueuse/router";
import { computedAsync } from "@vueuse/core";
import { storeToRefs } from "pinia";

import { Notes } from "@astrocentro-webapp/sellers/modules/commons/components/Notes";
import { TheHeader } from "@astrocentro-webapp/sellers/modules/commons/components/TheHeader";
import { ListConversations } from "@astrocentro-webapp/sellers/modules/chat/components/ListConversations";
import { ChatConnectionStatus } from "@astrocentro-webapp/ui-components/modules/chat/components/ChatConnectionStatus";
import { SellerPreConsultation } from "@astrocentro-webapp/sellers/modules/call/components/SellerPreConsultation";
import { ConsultationWindow } from "@astrocentro-webapp/sellers/modules/call/components/ConsultationWindow";
import { PlayStopNewConsultationAudio } from "@astrocentro-webapp/sellers/modules/commons/audios/PlayStopNewConsultationAudio.js";
import { ConsultationChannelEvents } from "@astrocentro-webapp/sellers/modules/chat/events/ConsultationChannelEvents";
import { GetConsultationsService } from "@astrocentro-webapp/sellers/modules/sellers/services/GetConsultationsService";
import { LoginService } from "@astrocentro-webapp/sellers/modules/login/services/LoginService";
import { useConsultationsStore } from "@astrocentro-webapp/sellers/stores/consultations";
import { useBreakpointSmallerThanMd } from "@astrocentro-webapp/ui-components/modules/commons/composables/browser/useBreakpointSmallerThanMd";
import { useActiveConsultationStore } from "@astrocentro-webapp/sellers/stores/activeConsultation";

const isNotesOpen = ref(false);
const showPreConsultationFeedback = ref(false);
const isFinishConsultationModalOpen = ref(false);
const consultationChannelEvents = ref(null);
const chatConsultationChannelEvents = ref(null);
provide("chat-consultation-channel-events", chatConsultationChannelEvents);
const { isSmallerThanMd } = useBreakpointSmallerThanMd();
const consultationId = useRouteParams("consultationId", 0, {
  transform: Number,
});
const consultationsStore = useConsultationsStore();
const activeConsultationStore = useActiveConsultationStore();
const getConsultationsService = new GetConsultationsService(consultationsStore);
const { consultations } = storeToRefs(consultationsStore);
const { activeConsultation } = storeToRefs(activeConsultationStore);
const consultation = computedAsync(async () => {
  if (!Object.keys(consultations.value).length) {
    await getConsultationsService.perform();
  }

  if (
    !("Chamadas Ativas" in consultations.value) ||
    !("Chamadas Inativas" in consultations.value)
  ) {
    return null;
  }

  let consultation = consultations.value["Chamadas Ativas"].find(
    (consultation) => consultation.id === consultationId.value
  );

  if (consultation) {
    consultationChannelEvents.value = new ConsultationChannelEvents(
      consultation.id
    );

    return consultation;
  }

  consultation = consultations.value["Chamadas Inativas"].find(
    (consultation) => consultation.id === consultationId.value
  );

  return consultation ?? null;
}, null);

const loginService = new LoginService();
const user = await loginService.getLoggedInUser();
const newConsultationAudio = new PlayStopNewConsultationAudio();

onBeforeRouteLeave(() => {
  if (activeConsultation.value?.id) {
    activeConsultation.value = {};
  }
});

onBeforeRouteUpdate(async (to) => {
  newConsultationAudio.stop();
});

onMounted(() => {
  newConsultationAudio.stop();
});

watch(showPreConsultationFeedback, () => {
  setTimeout(() => {
    showPreConsultationFeedback.value = false;
  }, 8000);
});
</script>

<template>
  <main class="d-flex flex-column">
    <ChatConnectionStatus
      v-if="isSmallerThanMd && consultation?.last_status !== 'finished'"
      :channelName="consultationChannelEvents?.channelName"
    />
    <TheHeader />
    <div class="container-fluid h-100">
      <div class="row h-100 position-relative">
        <div
          class="col-lg-4 px-0 d-none d-lg-block h-100 border-end border-gray-100 list-conversation"
        >
          <ListConversations />
        </div>
        <div class="col-lg-8 px-0">
          <div
            class="px-3 h-100"
            v-if="consultation?.last_status === 'requested'"
          >
            <div class="row justify-content-center align-items-center h-100">
              <div class="col-sm-8">
                <SellerPreConsultation
                  :consultation="consultation"
                  @open-chat-note="isNotesOpen = true"
                  @show-pre-consultation-feedback="
                    (event) => (showPreConsultationFeedback = event.value)
                  "
                />
              </div>
            </div>
          </div>

          <ConsultationWindow
            :consultation="consultation"
            :user="user"
            :isLoading="true"
            @intends-finish-consultation="isFinishConsultationModalOpen = true"
            @open-chat-note="isNotesOpen = true"
            v-if="
              !showPreConsultationFeedback &&
              consultation?.last_status !== 'requested'
            "
          />
          <div v-else class="h-100 d-flex align-items-center justify-content-center">
            <h3 class="text-center">
              Conectando com o seu cliente, permaneça na tela
            </h3>
          </div>
          <Notes
            :customer="consultation?.customer"
            :noteOpen="isNotesOpen"
            @close-chat-note="isNotesOpen = false"
          />
        </div>
      </div>
    </div>
  </main>
</template>
<style lang="scss" scoped>
@import "@astrocentro-webapp/ui-components/assets/stylesheets/variables.scss";
.list-conversation {
  max-width: $base-size * 51.25;
}
</style>