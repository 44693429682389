import { ConversationRepository } from "@astrocentro-webapp/sellers/modules/commons/repositories/ConversationRepository";
import { DateHelper } from "@astrocentro-webapp/sellers/modules/commons/helpers/DateHelper";

export class ConversationMessageRepository {
  #dateHelper;
  #conversationRepository;

  constructor() {
    this.#dateHelper = new DateHelper();
    this.#conversationRepository = new ConversationRepository();
  }

  /**
   * Helper function to get current conversation by chat room id.
   *
   * @param {number} chatRoomId
   *
   * @returns {(object | null)}
   */
  async #getConversationById(chatRoomId) {
    try {
      const currentDate = this.#dateHelper.format(null, "yyyy-MM-dd");
      const conversation = await this.#conversationRepository.getFirst({
        date: currentDate,
        chatRoomId,
      });

      return conversation;
    } catch (error) {
      console.log("[ #getConversationById - seller ]", error);
    }

    return null;
  }

  /**
   * Get message related to chat conversation.
   *
   * @param {number} chatRoomId
   * @param {number} id
   *
   * @returns {array}
   */
  async getFirst(chatRoomId, id) {
    try {
      const conversation = await this.#getConversationById(chatRoomId);
      let stored = { message: null, index: -1 };
      if (conversation) {
        conversation.messages.forEach((message, index) => {
          if (message.id === id) {
            stored = { ...stored, message, index };
          }
        });

        if (stored.message) {
          return [stored.message, stored.index];
        }

        return [stored.message, stored.index];
      }

      return [stored.message, stored.index];
    } catch (error) {
      console.log("[ getFirst - seller ]", error);
    }

    return [];
  }

  /**
   * Add message to chat conversation.
   *
   * @param {number} chatRoomId
   * @param {object} payload
   */
  async add(chatRoomId, payload) {
    try {
      const conversation = await this.#getConversationById(chatRoomId);

      if (conversation) {
        return this.#conversationRepository.update(conversation.id, {
          [`messages.${conversation.messages.length}`]: payload,
        });
      }
    } catch (error) {
      console.log("[ update - seller ]", error);
    }
  }

  /**
   * Update message related to chat conversation.
   *
   * @param {number} chatRoomId
   * @param {number} id
   * @param {object} payload
   */
  async update(chatRoomId, id, payload) {
    try {
      const conversation = await this.#getConversationById(chatRoomId);
      const [message, index] = await this.getFirst(chatRoomId, id);

      if (conversation && message) {
        return this.#conversationRepository.update(conversation.id, {
          [`messages.${index}`]: { ...message, ...payload },
        });
      }
    } catch (error) {
      console.log("[ update - seller ]", error);
    }
  }
}
