/**
 * Dispatches a 'show-notification' custom event that must be handled by the global notification component
 *
 * @param {string} type The notification type, possible values are: error
 * @param {string} message
 */
export const showNotification = (type, message) => {
  document.dispatchEvent(
    new CustomEvent("show-notification", { detail: { type, message } })
  );
};
