<script setup>
import { VDefaultAvatar } from "@astrocentro-webapp/ui-components/modules/commons/components/VDefaultAvatar";
import { AcceptChatConsultationService } from "@astrocentro-webapp/sellers/modules/chat/services/AcceptChatConsultationService.js";
import { ConfirmRefuseConsultationModal } from "@astrocentro-webapp/sellers/modules/commons/components/ConfirmRefuseConsultationModal";
import { ref } from "vue";

const acceptChatConsultationService = new AcceptChatConsultationService();

const props = defineProps({
  consultation: {
    type: Object,
    default() {
      return {
        id: null,
        customer: {
          name: null,
        },
      };
    },
  },
});

const openConfirmationModal = ref(false);
const isLoading = ref(false);

const acceptConsultation = async (consultationId) => {
  isLoading.value = true;

  setTimeout(async () => {
    await acceptChatConsultationService.perform(consultationId);
    isLoading.value = false;
  }, 2000);
};
</script>

<template>
  <div>
    <div class="text-center">
      <VDefaultAvatar size="lg" class="fs-1 fw-bold mb-5" />
      <h3 class="fs-4 text-gray-900">
        {{ props.consultation.customer?.name }} está te aguardando
      </h3>
      <p
        @click="$emit('open-chat-note')"
        class="fs-8 text-primary"
        role="button"
      >
        Abrir ficha do cliente
      </p>
      <p class="fs-8 text-gray-700 extra-margin">
        Você deseja realizar uma consulta com esse cliente?
      </p>
      <div class="d-grid">
        <button
          class="btn btn-primary mb-2"
          @click="acceptConsultation(props.consultation.id)"
          :disabled="isLoading"
          type="button"
        >
          Aceitar
        </button>
        <button
          class="btn btn-outline-gray-300 text-gray-700"
          @click="openConfirmationModal = true"
          :disabled="isLoading"
          type="button"
        >
          Recusar
        </button>
      </div>
    </div>
    <ConfirmRefuseConsultationModal
      :open="openConfirmationModal"
      :consultation="consultation"
      @modal-closed="openConfirmationModal = false"
    />
  </div>
</template>

<style lang="scss" scoped>
@import "@astrocentro-webapp/ui-components/assets/stylesheets/variables.scss";
.extra-margin {
  margin-bottom: $base-size * 8;
}
</style>
