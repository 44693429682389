import { storeToRefs } from "pinia";

import { MakeCallService } from "@astrocentro-webapp/sellers/modules/call/services/MakeCallService";
import { useCallStore } from "@astrocentro-webapp/sellers/stores/call";

export const handleClientCallDeviceRegisteredListener = async (event) => {
  try {
    const { consultation } = event;

    if (
      consultation.last_status !== "in-progress" ||
      !consultation.call_device_id
    ) {
      return;
    }

    const makeCallService = new MakeCallService(consultation);
    const result = await makeCallService.perform({
      device: { data: { identity: "seller_" + consultation.seller_id } },
      connect: {
        params: {
          To: consultation.call_device_id,
          ConsultationId: consultation.id.toString(),
        },
      },
    });

    if (!result) {
      return;
    }

    const store = useCallStore();
    const { call } = storeToRefs(store);
    call.value = result.call;
  } catch (error) {
    console.error("handleClientCallDeviceRegisteredListener", error);
  }
};
