import { DateHelper } from "@astrocentro-webapp/sellers/modules/commons/helpers/DateHelper";

export class FormatConversationMessagesHelper {
  static perform(conversation, consultation) {
    const dateHelper = new DateHelper();

    return conversation.messages.map((message) => {
      const time = dateHelper.format(
        `${conversation.date} ${message.time}Z`,
        "yyyy-MM-dd HH:mm:ss"
      );

      return {
        ...message,
        id: dateHelper.get(time).getTime(),
        time,
        isRead: true,
        hasError: false,
        sender: {
          id: message.sender,
          username:
            message.sender === consultation.seller.user_id
              ? consultation.seller.name
              : consultation.customer.name,
        },
      };
    });
  }
}
