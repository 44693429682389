<script setup>
import { onMounted } from "vue";
import { useRouter } from "vue-router";

import { LogoutService } from "@astrocentro-webapp/sellers/modules/login/services/LogoutService";
import { UpdateSellerServiceStatusService } from "@astrocentro-webapp/sellers/modules/sellers/services/UpdateSellerServiceStatusService";
import { useServicesStore } from "@astrocentro-webapp/sellers/stores/services";
import { PushNotificationsAdapter } from "@astrocentro-webapp/sellers/modules/commons/adapters/notifications/pusher/PushNotificationsAdapter";

const router = useRouter();
const logoutService = new LogoutService();
const store = useServicesStore();
const updateSellerServiceStatusService = new UpdateSellerServiceStatusService(
  store
);

onMounted(async () => {
  await updateSellerServiceStatusService.perform({
    "chat-consultation": false,
    "phone-consultation": false,
  });
  const pushNotificationsAdapter = new PushNotificationsAdapter();
  await pushNotificationsAdapter.stop();

  logoutService.perform();

  router.push("/login");
});
</script>

<template>
  <div class="h-100 d-flex align-items-center justify-content-center">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only"></span>
    </div>
    <div>
      <p class="mx-4 my-auto">Deslogando</p>
    </div>
  </div>
</template>
