<script setup>
import { reactive, ref, inject, defineAsyncComponent, watch } from "vue";
import { useDebounceFn, watchDebounced, onClickOutside } from "@vueuse/core";

import { VTextArea } from "@astrocentro-webapp/ui-components/modules/commons/components/VTextArea";
import { VButton } from "@astrocentro-webapp/ui/modules/commons/components/VButton";
import { DateHelper } from "@astrocentro-webapp/commons/modules/commons/helpers/DateHelper";
import { mobileCheck } from "@astrocentro-webapp/ui/modules/commons/utils/mobileCheck";

import { ChatRoomRepository } from "@astrocentro-webapp/commons/modules/commons/repositories/ChatRoomRepository";

import SendIcon from "@astrocentro-webapp/ui-components/assets/icons/send.svg?component";
import FaceSmile from "@astrocentro-webapp/ui-components/assets/icons/face-smile.svg?component";
import "vue3-emoji-picker/css";

const EmojiPicker = defineAsyncComponent(() => import("vue3-emoji-picker"));
const state = reactive({
  open: false,
  typingTimeout: 0,
});

const chatRoomRepository = new ChatRoomRepository(
  props.consultation?.chat_room_id
);

const props = defineProps({
  user: {
    type: Object,
    default() {
      return { id: null, username: null };
    },
  },
  consultation: {
    type: Object,
    default() {
      return {
        id: 0,
        chat_room_id: 0,
        external_id: null,
      };
    },
  },
});

const message = ref("");
const isTyping = ref(false);
const targetEmoji = ref(null);
const chatEvents = inject("chat-events");
const chatConsultationChannelEvents = inject(
  "chat-consultation-channel-events"
);
const dateHelper = new DateHelper();
const sender = {
  id: props.user.id,
  username: props.user[props.user.type].name,
  type: props.user.type,
};

const handleTyping = useDebounceFn(() => {
  if (!message.value || isTyping.value) isTyping.value = false;
}, 5000);

const handleSubmit = () => {
  if (message.value) {
    const time = dateHelper.format(null, "yyyy-MM-dd HH:mm:ss");
    const uniqueId = dateHelper.get(time).getTime() + Math.random().toString(36).substring(2, 15);

    chatEvents.emit("message-sent", {
      id: uniqueId,
      isRead: false,
      hasError: false,
      sender,
      recipientUser: { ...sender, isTyping: isTyping.value },
      message: {
        template: "text",
        payload: {
          message: message.value,
        },
      },
      time,
      chatRoomId: props.consultation.chat_room_id,
      consultationId: props.consultation.id,
      consultationExternalId: props.consultation.external_id,
    });

    message.value = "";
    state.open = false;
    clearTimeout(state.typingTimeout);
  }
};

const handleInput = () => {
  isTyping.value = true;

  handleTyping();
};

const onSelectEmoji = (emoji) => {
  handleInput();
  message.value = message.value.concat(" ", emoji.i);
};

const handleClickEmoji = () => {
  state.open = !state.open;
};

watch(isTyping, (value) => {
  if (!value) {
    clearTimeout(state.typingTimeout);
    return;
  }

  state.typingTimeout = setTimeout(async () => {
    const updateChatRoom = await chatRoomRepository.update("", {
      updated_at: new Date().toISOString(),
    });
  }, 150000);
});

watchDebounced(
  isTyping,
  (value) => {
    if (chatConsultationChannelEvents) {
      try {
        if (value) {
          chatConsultationChannelEvents.value.channel.trigger(
            "client-is-typing",
            {}
          );

          return;
        }

        chatConsultationChannelEvents.value.channel.trigger(
          "client-is-not-typing",
          {}
        );
      } catch (error) {
        // TODO: Error occurs when client can't trigger event because it's currently not subscribed to channel
        console.log("[ ChatInput - ui-components ]", error);
      }
    }
  },
  { debounce: 1000, maxWait: 5000 }
);

onClickOutside(targetEmoji, () => (state.open = false));
</script>

<template>
  <form
    method="POST"
    autocomplete="off"
    class="chat-input-container px-2 py-1 p-sm-3 text-end"
    @submit.prevent="handleSubmit"
    @keyup.enter="handleSubmit"
  >
    <VTextArea
      placeholder="Digite sua mensagem aqui"
      v-model.trim="message"
      @input="handleInput"
      class="position-relative d-flex align-items-end"
    >
      <div v-if="!mobileCheck()" class="me-2 d-inline-block" ref="targetEmoji">
        <VButton
          @click="handleClickEmoji"
          :class="`btn-sm ${
            state.open === true
              ? 'bg-gray-700 primary text-white'
              : 'text-gray-500'
          }`"
        >
          <FaceSmile width="20" height="20" />
        </VButton>
        <EmojiPicker
          v-show="state.open"
          :native="true"
          @select="onSelectEmoji"
          :hide-search="true"
          :disable-skin-tones="true"
          class="emoji-picker position-absolute"
        />
      </div>
      <VButton type="submit" class="btn-sm btn-primary">
        <SendIcon width="20" height="20" />
      </VButton>
    </VTextArea>
  </form>
</template>

<style lang="scss" scoped>
@import "@astrocentro-webapp/ui-components/assets/stylesheets/variables.scss";

.chat-input-container {
  flex: none;
  order: 3;
}
.emoji-picker {
  width: $base-size * 50;
  z-index: 999;
  right: $base-size * 14;
  bottom: $base-size * 2;
}
</style>
