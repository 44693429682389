import { HttpClient } from "@astrocentro-webapp/sellers/modules/commons/clients/HttpClient";

export class BaseRepository {
  httpClient;
  resource;

  constructor() {
    if (this.constructor === BaseRepository) {
      throw new Error("You cannot instantiate this class");
    }

    this.httpClient = HttpClient.create();
  }

  /**
   * Get the related resources
   *
   * @returns {JSON}
   */
  async get(params = null) {
    const { data } = await this.httpClient.get(`v1/${this.resource}` + params);

    return data;
  }

  /**
   * Get the specified resource
   *
   * @param {number} id
   * @returns {JSON}
   */
  async getById(id) {
    const { data } = await this.httpClient.get(`v1/${this.resource}/${id}`);

    return data;
  }

  /**
   * Create a new resource
   *
   * @param {JSON} payload
   * @returns {JSON}
   */
  async create(payload) {
    const { data } = await this.httpClient.post(`v1/${this.resource}`, payload);

    return data;
  }

  /**
   * Update the specified resource
   *
   * @param {number} id
   * @param {JSON} payload
   * @returns {JSON}
   */
  async update(id, payload) {
    const { data } = await this.httpClient.put(
      `v1/${this.resource}/${id}`,
      payload
    );

    return data;
  }

  /**
   * Delete the specified resource
   *
   * @param {number} id
   * @returns {JSON}
   */
  async delete(id) {
    const { data } = await this.httpClient.delete(`v1/${this.resource}/${id}`);

    return data;
  }
}
