import { toValue } from "vue";

import { CallClient } from "@astrocentro-webapp/commons/modules/commons/clients/CallClient";

export class MakeCallService {
  #consultation;

  constructor(consultation) {
    this.#consultation = toValue(consultation);
  }

  /**
   * Handle a canceled consultation call event.
   *
   * This function is responsible for handling a canceled consultation call. It invokes the `perform` method of the `finishCallConsultationService`
   * to update the consultation's status to 'finished' when the call is canceled either by the customer giving up or due to a timeout.
   * @returns A promise that resolves when the handling of the canceled call is complete.
   * @throws If there is an issue with handling the canceled call.
   */
  async #handleCanceledCall() {
    try {
      console.info(
        "Requested consultation was finished either by customer gaving up or timeout"
      );
    } catch (error) {
      console.error("MakeCallService.handleCanceledCall", error);
    }
  }

  /**
   * Perform a consultation call.
   *
   * This function initiates a consultation call with the specified options. It first checks if there is an authenticated user with seller information.
   * If the user is authenticated as a seller, it creates and initializes a call client using the `CallClient.getInstance` method. Once the call client is ready,
   * it establishes a call with the provided options and sets up event listeners for various call events, including cancellation, disconnection, and rejection.
   * @param options - The options for initiating the consultation call.
   * @returns A promise that resolves to an object containing the call client and call if the call initiation was successful; otherwise, `null`.
   * @throws If there is an issue with initiating the consultation call.
   */
  async perform(options) {
    try {
      const callClient = await CallClient.getInstance(options.device);

      if (callClient) {
        const activeCall = callClient.getActiveCall();

        if (activeCall) {
          activeCall.removeAllListeners();
          await callClient.disconnectAll();
          const result = await this.perform(options);

          return result;
        }

        const call = await callClient.connect(options.connect);
        call.removeAllListeners();

        call.on("cancel", async () => {
          await this.#handleCanceledCall();
        });

        call.on("disconnect", async () => {
          await this.#handleCanceledCall();
        });

        call.on("reject", async () => {
          await this.#handleCanceledCall();
        });

        return { callClient, call };
      }
    } catch (error) {
      console.error("MakeCallService.perform", error);
    }

    return null;
  }
}
