<script setup>
import { onMounted, ref, watch } from "vue";
import { useThrottleFn, useEventListener } from "@vueuse/core";

import { VDivider } from "@astrocentro-webapp/ui-components/modules/commons/components/VDivider";
import { ChatTypingDisplay } from "@astrocentro-webapp/ui-components/modules/chat/components/ChatTypingDisplay";
import { DateHelper } from "@astrocentro-webapp/commons/modules/commons/helpers/DateHelper";
import { config as messageTemplatesConfig } from "@astrocentro-webapp/ui-components/config/message-templates";
import { VIcon } from "@astrocentro-webapp/ui-components/modules/commons/components/VIcon";

const props = defineProps({
  user: {
    type: Object,
    default() {
      return { id: 0, username: "", type: "" };
    },
  },
  recipientUser: {
    type: Object,
    default() {
      return { name: "", thumbnail: "", isTyping: false };
    },
  },
  conversations: {
    type: Object,
    default() {
      return [];
    },
  },
  consultation: {
    type: Object,
    default() {
      return {
        end_date: "",
      };
    },
  },
});
const hasNewMessage = ref({});
const enableScroll = ref({});
const chatMessagesRef = ref(null);
const isTypingDisplayVisible = ref(false);
const dateHelper = new DateHelper();

const getConversationDividerLabel = (date) => {
  const newDate = dateHelper.get(date);

  if (dateHelper.isToday(newDate)) {
    return "Hoje";
  }

  return dateHelper
    .intlFormat(newDate, {
      day: "numeric",
      month: "short",
    })
    .replace(",", "");
};

const handleScroll = useThrottleFn((firstLoad = false) => {
  setTimeout(() => {
    if (firstLoad || (chatMessagesRef.value && enableScroll.value[props.user.id]))
      chatMessagesRef.value.scrollTop = chatMessagesRef.value.scrollHeight;
  }, 100);
}, 400);

const checkScroll = () => {

  const { scrollTop, offsetHeight, scrollHeight } = chatMessagesRef.value;

  if(scrollTop === 0) {
    enableScroll.value[props.user.id] = true;
    hasNewMessage.value[props.user.id] = false;
  } else {
    if(parseInt(scrollTop + offsetHeight) >= (scrollHeight-100)) {
      enableScroll.value[props.user.id] = true;
      hasNewMessage.value[props.user.id] = false;
    } else {
      enableScroll.value[props.user.id] = false;
    }
  }
}

watch(
  () => props.conversations,
  () => {
    if (isTypingDisplayVisible.value) isTypingDisplayVisible.value = false;
    handleScroll();
  }
);

watch(
  () => chatMessagesRef.value,
  () => handleScroll()
);

watch(isTypingDisplayVisible, (isTyping) => {
  isTyping && handleScroll();
});

useEventListener(document, "new-message-received", () => {
  if (!enableScroll.value[props.user.id]) hasNewMessage.value[props.user.id] = true;
  handleScroll();
});

useEventListener(
  document,
  "client-is-typing",
  () => (isTypingDisplayVisible.value = true)
);

useEventListener(
  document,
  "client-is-not-typing",
  () => (isTypingDisplayVisible.value = false)
);

onMounted(() => {
  hasNewMessage.value[props.user.id] = false;
  enableScroll.value[props.user.id] = true;
  handleScroll(true);
});
</script>

<template>
  <div class="chat-messages position-relative">
    <div
      @scroll="checkScroll"
      ref="chatMessagesRef"
      class="chat-messages-overflow position-absolute w-100 h-100 px-3"
    >
      <div
        class="d-flex flex-column"
        v-for="conversation of props.conversations"
      >
        <VDivider :label="getConversationDividerLabel(conversation?.date)" />
        <component
          v-for="message of conversation?.messages"
          :is="messageTemplatesConfig[message.message.template]"
          :user="props.user"
          :recipientUser="props.recipientUser"
          :data="{ ...message, consultation: props.consultation }"
        />
      </div>
      <ChatTypingDisplay
          :isVisible="isTypingDisplayVisible"
          :recipientUser="props.recipientUser"
      />
    </div>
    <div
      class="has-new-message p-1 position-absolute bottom-0"
      :class="{ show: hasNewMessage[props.user.id] }"
    >
      <div class="d-flex has-new-message-bg p-2 rounded-1">
        <span class="mx-2">você tem mensagens não lidas</span> <VIcon icon="arrow-down" :width="15" :height="15" viewBox="0 0 20 20" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@astrocentro-webapp/ui-components/assets/stylesheets/variables.scss";

.has-new-message .has-new-message-bg {
  background: $gold;
}

.has-new-message {
  font-size: $base-size * 1.5;
  background: $white;
  opacity: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  transition: opacity 750ms ease-in-out;
}

.has-new-message.show {
  opacity: 1;
  z-index: 999;
}

.chat-messages {
  flex: 1 1 0;
  order: 2;

  &-overflow {
    z-index: 99;
    overflow-x: hidden;
    overflow-y: scroll;
    scroll-behavior: smooth;

    &::-webkit-scrollbar-track {
      background: $white;
    }
  }
}
</style>
