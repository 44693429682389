import { DatabaseAdapter } from "@astrocentro-webapp/sellers/modules/commons/adapters/DatabaseAdapter";

export class LogoutService {
  /**
   * Logs out the user removing their access token from the browser storage
   */
  perform() {
    const databaseAdapter = new DatabaseAdapter();

    localStorage.removeItem("accessToken");
    localStorage.removeItem("user");
    databaseAdapter.delete();
  }
}
